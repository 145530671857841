import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import CoveyLogoWhite from '../../assets/images/svgs/coveyLogoWhite.svg';
import CoveyLogoTextWhite from '../../assets/images/svgs/coveyLogoTextWhite.svg';
import React, { useState } from 'react';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { loadingStore, useLoginStore } from '../../store';
import { useForm } from 'react-hook-form';
import Step1 from './Step1';

function SignupForm() {
  const toast = useToast();
  const navigate = useNavigate();

  return (
    <Box
      display={'flex'}
      flexDirection="column"
      margin={['24px', '40px']}
      height={['calc(100% - 48px)']}
      bg={['#353535', '#353535']}
    >
      <Box display={'flex'} gap="10px">
        <Image src={CoveyLogoWhite} />
        <Image src={CoveyLogoTextWhite} />
      </Box>
      <Text color={'#fff'} fontSize="16px" fontWeight={600}>
        Signup to your admin dashboard
      </Text>
      {<Step1 />}
    </Box>
  );
}
export default SignupForm;
