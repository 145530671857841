// import { Center, Link, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// import { Pagination } from 'antd';
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import configureAxios from '../AxiosClient';
// import { TagRow } from '../components/tokenHolder/tags/TagRow';
// import { TableDataTextProps, TableHeaderTextProps, TokenHolder } from '../interfaces/tokenHolderInterface';
// import { loadingStore, useLoginStore } from '../store';
// import { numberWithCommas } from '../utils/helper';
// import axios from '../api/axios';
// import { Input } from 'antd';
// const { Search } = Input;

// export const TableHeaderText = (props: TableHeaderTextProps) => {
//   const { text } = props;
//   return (
//     <Th textTransform="capitalize" fontSize={'16px'} fontWeight="600" color="#000">
//       {text}
//     </Th>
//   );
// };

// export const TableDataText = (props: TableDataTextProps) => {
//   const { text } = props;
//   return (
//     <Td color="#000" fontSize="14px">
//       {text}
//     </Td>
//   );
// };

// export const TokenHolders = () => {
//   const navigate = useNavigate();
//   const toast = useToast();
//   const axiosInstance = configureAxios();
//   const setLoading = loadingStore((state: any) => state.setLoading);
//   const loading = loadingStore((state: any) => state.loading);
//   const [tokenHolders, setTokenHolder] = useState<TokenHolder[]>([]);
//   const [reTrigger, setReTrigger] = useState(false);
//   const [pageIndex, setPageIndex] = useState(() => {
//     const savedPage = localStorage.getItem('tokenHoldersPageIndex');
//     return savedPage ? parseInt(savedPage, 10) : 1; // Default to 1
//   });
//   const [pageSize, setPageSize] = useState(() => {
//     const savedSize = localStorage.getItem('tokenHoldersPageSize');
//     return savedSize ? parseInt(savedSize, 10) : 10;
//   });
//   const [totalCount, setTotalCount] = useState(0);
//   const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

//   const userData = useLoginStore((state: any) => state.userData);

//   const [searchQuery, setSearchQuery] = useState('');

//   const navigateToSinglePage = (address: string, labels: string[]) => {
//     setSelectedWalletAddress(address);
//     navigate(`/tokenHolders/${address}`, {
//       state: {
//         address: address,
//         labels: labels,
//       },
//     });
//   };

//   const calculateAdjustedBalance = (balance: string, decimals: string) => {
//     const adjustedBalance = Number(balance) / Math.pow(10, Number(decimals));
//     return numberWithCommas(adjustedBalance.toFixed(2));
//   };

//   const getTokenHolders = () => {
//     setLoading(true);
//     axios
//       .get(`/api/token-holders/?page=${pageIndex ?? 1}&page_size=${pageSize}&search=${searchQuery}`)
//       .then((res) => {
//         const isStatusOk = res.status === 200;
//         if (isStatusOk) {
//           console.log('Token holders response:', res.data); // Add this line
//           setTokenHolder(res.data.results);
//           setTotalCount(res.data.count);
//         } else {
//           // toast({
//           //   title: 'There was an error fetching data',
//           //   status: 'error',
//           //   isClosable: true,
//           // });
//         }
//       })
//       .catch((err: unknown) => {
//         console.error('Error fetching token holders:', err);
//         toast({
//           title: 'Error fetching token holders',
//           description: 'Please try again later',
//           status: 'error',
//           isClosable: true,
//         });
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     getTokenHolders();
//   }, [pageIndex, pageSize, reTrigger, searchQuery]);

//   useEffect(() => {
//     localStorage.setItem('tokenHoldersPageIndex', pageIndex.toString());
//     localStorage.setItem('tokenHoldersPageSize', pageSize.toString());
//   }, [pageIndex, pageSize]);

//   const handlePageChange = (page: number, size?: number) => {
//     setPageIndex(page);
//     if (size) setPageSize(size);
//     localStorage.setItem('tokenHoldersPageIndex', page.toString());
//     localStorage.setItem('tokenHoldersPageSize', size ? size.toString() : pageSize.toString());
//   };

//   const updateTag = async (id: number, labels: string[]) => {
//     setLoading(true);
//     try {
//       const response = await axios.put(`/api/token-holders/${id}/update/`, {
//         labels,
//       });
//       if (response.status === 200) {
//         // Update the local state with the new data
//         setTokenHolder((prevHolders) =>
//           prevHolders.map((holder) => (holder.id === id ? { ...holder, labels: response.data.labels } : holder)),
//         );
//         setReTrigger(!reTrigger);
//         toast({
//           title: 'Tags updated successfully',
//           status: 'success',
//           isClosable: true,
//         });
//       }
//     } catch (error) {
//       console.error('Error updating tags:', error);
//       toast({
//         title: 'Error updating tags',
//         status: 'error',
//         isClosable: true,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const deleteTag = async (id: number, labelToDelete: string) => {
//     setLoading(true);
//     try {
//       await axios.delete(`/api/token-holders/${id}/update/`, {
//         data: { label: labelToDelete },
//       });

//       setReTrigger(!reTrigger);
//       toast({
//         title: 'Tag deleted successfully',
//         status: 'success',
//         isClosable: true,
//       });
//     } catch (error) {
//       console.error('Error deleting tag:', error);
//       toast({
//         title: 'Error deleting tag',
//         status: 'error',
//         isClosable: true,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSearch = (value: string) => {
//     setSearchQuery(value);
//     setPageIndex(1);
//     localStorage.setItem('tokenHoldersPageIndex', '1');
//   };

//   if (loading) {
//     return (
//       <Center height="100vh">
//         <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
//       </Center>
//     );
//   }

//   return (
//     <>
//       <Search
//         placeholder="Search by wallet address or tag"
//         allowClear
//         enterButton="Search"
//         size="large"
//         onSearch={handleSearch}
//         style={{ maxWidth: '500px', marginBottom: '20px' }}
//       />
//       <TableContainer overflowY="scroll">
//         <Table marginTop={'40px'} backgroundColor="#FFF" borderRadius={'20px'} size="lg">
//           <Thead marginBottom={'16px'}>
//             <Tr>
//               <TableHeaderText text={'Wallet Address'} />
//               <TableHeaderText text={'Balance'} />
//               <TableHeaderText text={'Tag'} />
//             </Tr>
//           </Thead>
//           <Tbody>
//             {tokenHolders &&
//               tokenHolders.map((tokenHolder: TokenHolder) => (
//                 <Tr key={tokenHolder?.id}>
//                   <Td>
//                     <Link color="#4096ff" onClick={() => navigateToSinglePage(tokenHolder.address, tokenHolder.labels)}>
//                       {`${tokenHolder?.address.slice(0, 12)}...${tokenHolder?.address?.slice(-12)}`}
//                     </Link>
//                   </Td>
//                   <TableDataText
//                     text={calculateAdjustedBalance(tokenHolder?.balance, tokenHolder?.contract_decimals)}
//                   />
//                   <Td>
//                     <TagRow
//                       label={tokenHolder?.labels || []}
//                       id={tokenHolder?.id}
//                       setReTrigger={setReTrigger}
//                       reTrigger={reTrigger}
//                       updateTag={updateTag}
//                       deleteTag={deleteTag}
//                     />
//                   </Td>
//                 </Tr>
//               ))}
//           </Tbody>
//         </Table>
//       </TableContainer>
//       {tokenHolders && tokenHolders.length > 0 && (
//         <Pagination
//           current={pageIndex}
//           total={totalCount}
//           pageSize={pageSize}
//           onChange={handlePageChange}
//           showSizeChanger
//           showQuickJumper
//           showTotal={(total) => `Total ${total} items`}
//           pageSizeOptions={['10', '25', '50']}
//           style={{ marginTop: '16px', textAlign: 'right' }}
//         />
//       )}
//     </>
//   );
// };

import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import TokenHolderBG from '../assets/images/pngs/TokenHolderBG.png';
import RefreshIcon from '../assets/images/svgs/RefreshIcon.svg';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import TagEditModal from '../components/modals/TagEditModal';
import { BalanceIcon } from '../Icons/BalanceIcon';
import { PlusIcon } from '../Icons/PlusIcon';
import { TagIcon } from '../Icons/TagIcon';

import { BalanceFilter } from '../Icons/BalanceFilter';
import { FilterTag } from '../Icons/FilterTag';
import { loadingStore, useLoginStore } from '../store';
import { numberWithCommas } from '../utils/helper';
import TablePagination from './TablePagination';

interface TokenHolder {
  id: number;
  address: string;
  balance: string;
  contract_decimals: string;
  labels: string[];
}

const TokenHolders = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [balanceSort, setBalanceSort] = useState<'none' | 'asc' | 'desc'>('desc');
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [tokenHolders, setTokenHolder] = useState<TokenHolder[]>([]);
  const [reTrigger, setReTrigger] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pageIndex, setPageIndex] = useState(() => {
    const savedPage = localStorage.getItem('tokenHoldersPageIndex');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    const savedSize = localStorage.getItem('tokenHoldersPageSize');
    return savedSize ? parseInt(savedSize, 10) : 10;
  });
  const [totalCount, setTotalCount] = useState(0);
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedHolder, setSelectedHolder] = useState<TokenHolder | null>(null);
  const [allAvailableTags, setAllAvailableTags] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const collectUniqueTags = (holders: TokenHolder[]) => {
    const uniqueTags = new Set<string>();
    holders.forEach((holder) => {
      if (holder.labels && holder.labels.length > 0) {
        holder.labels.forEach((label) => uniqueTags.add(label));
      }
    });
    return Array.from(uniqueTags);
  };

  const navigateToSinglePage = (address: string, labels: string[]) => {
    setSelectedWalletAddress(address);
    navigate(`/tokenHolders/${address}`, {
      state: {
        address: address,
        labels: labels,
      },
    });
  };

  const calculateAdjustedBalance = (balance: string, decimals: string) => {
    const adjustedBalance = Number(balance) / Math.pow(10, Number(decimals));
    return numberWithCommas(adjustedBalance.toFixed(2));
  };

  const handleUpdateTags = async (updatedTag: string) => {
    if (!selectedHolder) return;

    setLoading(true);
    try {
      const response = await axios.put(`/api/token-holders/${selectedHolder.id}/update/`, {
        labels: [updatedTag],
      });
      if (response.status === 200) {
        setTokenHolder((prevHolders) =>
          prevHolders.map((holder) => (holder.id === selectedHolder.id ? { ...holder, labels: [updatedTag] } : holder)),
        );
        toast({
          title: 'Tag updated successfully',
          status: 'success',
          isClosable: true,
        });
        setIsTagModalOpen(false);
        setSelectedHolder(null);
      }
    } catch (error) {
      console.error('Error updating tag:', error);
      toast({
        title: 'Error updating tag',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTokenHoldersUpdate = async () => {
    setIsRefreshing(true);
    setIsUpdating(true);
    try {
      const response = await axios.post(
        `/api/user/trigger-update-user-tokenholders/`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Update triggered successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        const now = new Date().toISOString();
        await axios.patch(
          '/api/user/update-last-update-fields/',
          { last_tokenholders_update: now },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );

        await getTokenHolders();
      }
    } finally {
      setIsRefreshing(false);
      setIsUpdating(false);
    }
  };
  const getTokenHolders = () => {
    setLoading(true);
    axios
      .get(`/api/token-holders/?page=${pageIndex}&page_size=${pageSize}&search=${searchQuery}`)
      .then((res) => {
        if (res.status === 200) {
          setTokenHolder(res.data.results);
          setTotalCount(res.data.count);
          setLastUpdated(new Date().toLocaleTimeString());
          setAllAvailableTags(collectUniqueTags(res.data.results));
        }
      })
      .catch((err: unknown) => {
        console.error('Error fetching token holders:', err);
        toast({
          title: 'Error fetching token holders',
          description: 'Please try again later',
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
        setIsRefreshing(false);
      });
  };

  useEffect(() => {
    getTokenHolders();
  }, [pageIndex, pageSize, searchQuery]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const executeSearch = () => {
    setSearchQuery(searchInput);
    setPageIndex(1);
    localStorage.setItem('tokenHoldersPageIndex', '1');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      executeSearch();
    }
  };

  const handleRefresh = () => {
    handleTokenHoldersUpdate();
  };

  const handleOpenTagModal = (holder: TokenHolder) => {
    setSelectedHolder(holder);
    setIsTagModalOpen(true);
  };

  const handleBalanceSort = () => {
    const nextSort = balanceSort === 'none' ? 'asc' : balanceSort === 'asc' ? 'desc' : 'none';
    setBalanceSort(nextSort);

    // Sort the data
    setTokenHolder((prevHolders) => {
      const sortedHolders = [...prevHolders].sort((a, b) => {
        const balanceA = Number(a.balance) / Math.pow(10, Number(a.contract_decimals));
        const balanceB = Number(b.balance) / Math.pow(10, Number(b.contract_decimals));

        if (nextSort === 'asc') {
          return balanceA - balanceB;
        } else if (nextSort === 'desc') {
          return balanceB - balanceA;
        } else {
          return 0;
        }
      });
      return sortedHolders;
    });
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Center>
    );
  }
  return (
    <Box
      display="flex"
      padding="14px 24px 40px 24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="24px"
      flex="1 0 0"
      alignSelf="stretch"
    >
      <Box
        display="flex"
        height="200px"
        padding="24px"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        alignSelf="stretch"
        borderRadius="24px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        backgroundImage={`url(${TokenHolderBG})`}
        backgroundSize="cover"
        backgroundPosition="center"
        margin={0}
      >
        <Text
          color="var(--Cascader-colorWhite, #FFF)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="36px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="28px"
        >
          Token Holders
        </Text>

        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="10px">
          <Flex
            padding="6px 12px 6px 6px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            borderRadius="22px"
            background="#000"
            cursor="pointer"
            onClick={handleRefresh}
            position="relative"
            opacity={isRefreshing ? 0.7 : 1}
          >
            <Image
              src={RefreshIcon}
              alt="refresh"
              style={{
                animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
              }}
            />
            <Text
              color="var(--Button-colorTextLightSolid, #FFF)"
              fontFamily="Poppins"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="22px"
            >
              {isRefreshing ? 'Refreshing...' : 'Refresh'}
            </Text>
          </Flex>

          <Text
            color="var(--Text-Secondary-default, #FFF)"
            fontFamily="Poppins"
            fontSize="12px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="22px"
          >
            Last updated at {lastUpdated}.
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="var(--Neutrals-1, #FFF)"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        {/* Search Header */}
        <Flex width="100%" justify="space-between" align="center">
          <Text
            color="var(--Text-Black, #000)"
            textAlign="center"
            fontFamily="Poppins"
            fontSize="20px"
            fontWeight="600"
            lineHeight="28px"
          >
            Token Holders
          </Text>
          <InputGroup
            width="fit-content"
            padding="0px 20px 0px 12px"
            gap="10px"
            border="0.5px solid var(--Neutrals-4, #E9E9E9)"
            borderRadius="16px"
            background="var(--Neutrals-2, #F6F6F6)"
            alignItems="center"
          >
            <Image onClick={executeSearch} src={SearchIcon} alt="search" />

            <Input
              border="none"
              color={'#000'}
              padding={0}
              placeholder="Search by address or tag"
              background="transparent"
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              value={searchInput}
              _placeholder={{
                color: 'var(--Text-Primary-description, #909090)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '500',
              }}
              _focus={{
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </InputGroup>
        </Flex>

        {/* Table */}
        <Table variant="unstyled">
          <Thead>
            <Tr
              display="flex"
              height="40px"
              padding="0px 12px"
              alignItems="center"
              borderRadius="12px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Address
              </Th>
              <Th
                padding={'0px 12px'}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                alignItems={'center'}
                display={'flex'}
                justifyContent={'space-between'}
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Balance
                <BalanceFilter sort={balanceSort} onSort={handleBalanceSort} />
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                paddingRight={'2px'}
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tag
              </Th>
              <div style={{ display: 'flex', justifyContent: 'right', paddingRight: '12px' }}>
                <Th padding={0} flex="0.2" border="none">
                  <FilterTag />
                </Th>
              </div>
            </Tr>
          </Thead>
          <Tbody>
            {tokenHolders.map((holder: TokenHolder, index: number) => (
              <Tr
                key={holder.id || index}
                display="flex"
                alignItems="center"
                padding="12px"
                _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                borderBottom="1px solid rgba(246, 246, 246, 0.8)"
              >
                <Td padding={0} flex="1" border="none">
                  <Text
                    padding={0}
                    color="var(--Text-action, #4096FF)"
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="22px"
                    cursor="pointer"
                    onClick={() => navigateToSinglePage(holder.address, holder.labels)}
                  >
                    {`${holder.address.slice(0, 12)}...${holder.address.slice(-12)}`}
                  </Text>
                </Td>
                <Td padding={0} flex="1" border="none">
                  <Flex display="flex" alignItems="center" gap="8px">
                    <BalanceIcon />
                    <Text
                      color="var(--Text-Primary-default, #000)"
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="22px"
                      padding={0}
                    >
                      {calculateAdjustedBalance(holder.balance, holder.contract_decimals)}
                    </Text>
                  </Flex>
                </Td>
                <Td padding={0} flex="1" border="none">
                  <Flex alignItems="center" gap="4px" borderRadius="4px" cursor="pointer">
                    <TagIcon
                      fill={holder.labels?.length > 0 ? '#4096FF' : 'var(--Text-Primary-description, #909090)'}
                    />
                    <Text
                      color={
                        holder.labels?.length > 0
                          ? 'var(--Text-Primary-default, #000)'
                          : 'var(--Text-Primary-description, #909090)'
                      }
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="22px"
                      padding={0}
                      onClick={() => handleOpenTagModal(holder)}
                    >
                      {holder.labels?.length > 0 ? holder.labels[0] : 'Add tag'} {/* Only display first tag */}
                    </Text>
                  </Flex>
                </Td>
                <div style={{ display: 'flex', justifyContent: 'right', paddingRight: '12px' }}>
                  <Td flex="0.2" border="none" padding={0}>
                    <Box
                      _hover={{
                        color: '#4096FF',
                      }}
                      cursor="pointer"
                      padding={0}
                      color="var(--Text-Primary-description, #909090)"
                      onClick={() => handleOpenTagModal(holder)}
                    >
                      <PlusIcon fill={'currentcolor'} />
                    </Box>
                  </Td>
                </div>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <TablePagination
          currentPage={pageIndex}
          totalCount={totalCount}
          onPageChange={(page) => {
            setPageIndex(page);
            localStorage.setItem('tokenHoldersPageIndex', page.toString());
          }}
          hasNext={pageIndex * pageSize < totalCount}
          hasPrevious={pageIndex > 1}
        />

        {/* Tag Edit Modal */}
        {selectedHolder && (
          <TagEditModal
            open={isTagModalOpen}
            onClose={() => {
              console.log('Closing modal');
              setIsTagModalOpen(false);
              setSelectedHolder(null);
            }}
            id={selectedHolder.id}
            initialTags={selectedHolder.labels || []}
            onTagsUpdated={(updatedTags) => handleUpdateTags(updatedTags)}
          />
        )}
      </Box>
      <Modal
        isOpen={isUpdating}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => setIsUpdating(false)}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color={'#1f1f1f'}>
                Fetching Latest Data
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color={'#1f1f1f'} mt={4}>
                This may take some time, please be patient
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TokenHolders;
