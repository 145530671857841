import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const BookIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    minW="24px"
    minH="24px"
    boxSize="24px"
    fill="none"
    {...props}
  >
    <path d="M6.75 22.0001C6 22.0001 5.35417 21.7459 4.8125 21.2376C4.27083 20.7293 4 20.1001 4 19.3501V5.4001C4 4.76676 4.19583 4.2001 4.5875 3.7001C4.97917 3.2001 5.49167 2.88343 6.125 2.7501L13.625 1.2751C14.2417 1.14176 14.7917 1.2751 15.275 1.6751C15.7583 2.0751 16 2.59176 16 3.2251V15.1501C16 15.6334 15.85 16.0626 15.55 16.4376C15.25 16.8126 14.8667 17.0418 14.4 17.1251L6.525 18.7001C6.375 18.7334 6.25 18.8126 6.15 18.9376C6.05 19.0626 6 19.2001 6 19.3501C6 19.5334 6.075 19.6876 6.225 19.8126C6.375 19.9376 6.55 20.0001 6.75 20.0001H18V5.0001C18 4.71676 18.0958 4.47926 18.2875 4.2876C18.4792 4.09593 18.7167 4.0001 19 4.0001C19.2833 4.0001 19.5208 4.09593 19.7125 4.2876C19.9042 4.47926 20 4.71676 20 5.0001V20.0001C20 20.5501 19.8042 21.0209 19.4125 21.4126C19.0208 21.8043 18.55 22.0001 18 22.0001H6.75Z" />
  </Icon>
);
