import { GraphComponentProps } from '../../../interfaces';
import { getDate } from '../../../utils/helper';
import { ChartOptions } from 'chart.js';
import { Box, Text } from '@chakra-ui/react';
import { Line } from 'react-chartjs-2';
import React from 'react';

export const GraphComponent: React.FC<GraphComponentProps> = (props) => {
  const {
    textColor,
    textMain,
    price,
    graphBorderColor,
    gradientTopColor,
    gradientBottomColor,
    timeStamps,
    graphData,
    showGraph,
  } = props;

  function getGradient(ctx: CanvasRenderingContext2D, chartArea: { top: number; bottom: number }) {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

    gradient.addColorStop(0, gradientTopColor || '');
    gradient.addColorStop(1, gradientBottomColor || '');

    return gradient;
  }

  const data = {
    labels: getDate(timeStamps),
    datasets: [
      {
        label: 'Transaction volume',
        backgroundColor: function (context: {
          chart: { ctx: CanvasRenderingContext2D; chartArea: { top: number; bottom: number } | undefined };
        }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return;
          return getGradient(ctx, chartArea);
        },
        borderColor: graphBorderColor,
        borderWidth: 2,
        hoverBackgroundColor: gradientTopColor,
        hoverBorderColor: gradientTopColor,
        data: graphData,
        fill: true,
        tension: 0.8,
        pointRadius: 0,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  if (!showGraph) {
    return null;
  }

  return (
    <Box
      borderRadius={'12px'}
      border="1px solid #E5E5E5"
      bg="#FFF"
      w={['100%', '100%', '45%', '45%', '24%']}
      height="auto"
      flexGrow={1}
      display="flex"
      justifyContent={'space-between'}
      flexDirection="column"
    >
      <Box padding={'16px 16px 0px 16px'} display="flex" justifyContent={'space-between'}>
        <Text color={'#000'} fontSize="20px" fontWeight={600}>
          {textMain}
        </Text>
      </Box>
      <Text padding={'0px 16px 0px 16px'} color={'#909090'} fontSize="14px" fontWeight={400}>
        {price}
      </Text>

      <Box h="auto" minWidth="100%">
        <Line data={data} options={options} />
      </Box>
    </Box>
  );
};
