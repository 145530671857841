import { Box, Spinner, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { loadingStore, useLoginStore, UserDataType } from '../../../../store';
import axios from '../../../../api/axios';

function LoadingScreen() {
  const toast = useToast();
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const setFromLoadingPage = useLoginStore((state: any) => state.setFromLoadingPage);
  const [loading, setLoading] = useState(true);
  const [currentUserData, setCurrentUserData] = useState<UserDataType>();
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const fetchTokenAddressData = async (data: string) => {
    const tokenAddressData = {
      address: data,
    };
    try {
      const response = await axios.post('/api/user/update-token-address/', tokenAddressData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      if (response.status === 200) {
        setFromLoadingPage(true);
        setLoginDetails(currentUserData);
        toast({
          title: 'Success',
          description: 'Token address successfully updated',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setStepNumber(5);
    }
  };

  useEffect(() => {
    axios
      .get('/api/user/current/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        setCurrentUserData(response?.data?.data);
      })
      .catch((err: any) => {
        console.log('Error fetching user data:', err);
      });
  }, []);

  useEffect(() => {
    if (currentUserData?.token_address) {
      fetchTokenAddressData(currentUserData?.token_address);
    }
  }, [currentUserData]);

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" height="100vh">
      {loading && (
        <>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text
            color="var(--Text-Primary-default, #000)"
            textAlign="center"
            fontSize="30px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="38px"
          >
            We are fetching the data.
          </Text>
          <Text
            color="var(--Text-Primary-default, #909090)"
            textAlign="center"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="22px"
          >
            It can take some minutes, once done you will be redirected!
          </Text>
        </>
      )}
    </Box>
  );
}

export default LoadingScreen;
