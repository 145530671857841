import { Box } from '@chakra-ui/react';
import React from 'react';
interface DaysFilterProps {
  onTimeRangeChange: (range: string) => void;
  activeTimeRange?: string;
}

const DaysFilter: React.FC<DaysFilterProps> = ({ onTimeRangeChange, activeTimeRange = 'all' }) => {
  const timeRanges = [
    { label: '24h', value: '24h' },
    { label: '7d', value: '7d' },
    { label: '30d', value: '30d' },
    { label: 'All', value: 'all' },
  ];

  return (
    <Box
      display="flex"
      padding="2px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      background="var(--Neutrals-2, #F6F6F6)"
    >
      {timeRanges.map(({ label, value }) => (
        <Box
          key={value}
          display="flex"
          padding="8px 16px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="5px"
          background={activeTimeRange === value ? '#FFF' : 'transparent'}
          cursor="pointer"
          onClick={() => onTimeRangeChange(value)}
          transition="background 0.2s"
          _hover={{
            background: activeTimeRange === value ? '#FFF' : 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <div
            style={{
              color: activeTimeRange === value ? '#4096FF' : 'var(--Text-Secondary-description, #909090)',
              fontFamily: 'VisbyCF',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {label}
          </div>
        </Box>
      ))}
    </Box>
  );
};

export default DaysFilter;
