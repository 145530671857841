import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const FilterTag = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <mask id="mask0_3125_1049" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <rect x="6.10352e-05" width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3125_1049)">
      <path
        d="M8.25006 13.5C8.03756 13.5 7.85944 13.4281 7.71569 13.2844C7.57194 13.1406 7.50006 12.9625 7.50006 12.75C7.50006 12.5375 7.57194 12.3594 7.71569 12.2156C7.85944 12.0719 8.03756 12 8.25006 12H9.75006C9.96256 12 10.1407 12.0719 10.2844 12.2156C10.4282 12.3594 10.5001 12.5375 10.5001 12.75C10.5001 12.9625 10.4282 13.1406 10.2844 13.2844C10.1407 13.4281 9.96256 13.5 9.75006 13.5H8.25006ZM5.25006 9.75C5.03756 9.75 4.85944 9.67812 4.71569 9.53438C4.57194 9.39062 4.50006 9.2125 4.50006 9C4.50006 8.7875 4.57194 8.60938 4.71569 8.46562C4.85944 8.32187 5.03756 8.25 5.25006 8.25H12.7501C12.9626 8.25 13.1407 8.32187 13.2844 8.46562C13.4282 8.60938 13.5001 8.7875 13.5001 9C13.5001 9.2125 13.4282 9.39062 13.2844 9.53438C13.1407 9.67812 12.9626 9.75 12.7501 9.75H5.25006ZM3.00006 6C2.78756 6 2.60944 5.92813 2.46569 5.78437C2.32194 5.64062 2.25006 5.4625 2.25006 5.25C2.25006 5.0375 2.32194 4.85938 2.46569 4.71562C2.60944 4.57187 2.78756 4.5 3.00006 4.5H15.0001C15.2126 4.5 15.3907 4.57187 15.5344 4.71562C15.6782 4.85938 15.7501 5.0375 15.7501 5.25C15.7501 5.4625 15.6782 5.64062 15.5344 5.78437C15.3907 5.92813 15.2126 6 15.0001 6H3.00006Z"
        fill="#1C1B1F"
      />
    </g>
  </Icon>
);
