import { Box, Spinner, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadingStore } from '../../../../store';
import axios from '../../../../api/axios';

function InOutFlowLoadingScreen() {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const updateInflowOutFlowAddresses = async () => {
    try {
      const response = await axios.post(
        '/api/user/update-inflow-outflow-addresses/',
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );
      if (response.status === 200) {
        setStepNumber(7);
        toast({
          title: 'Success',
          description: 'Inflow outflow addresses have been updated',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      // toast({
      //   title: 'Error',
      //   description: 'Something went wrong while updating Inflow outflow addresses',
      //   status: 'error',
      //   duration: 9000,
      //   isClosable: true,
      // });
    } finally {
      setLoading(false);
      // Instead of navigating to the dashboard, we're setting the step number to 7
      // which should correspond to the TokenHolderCsvForm in the LoginStepsMain component
      setStepNumber(7);
    }
  };

  useEffect(() => {
    updateInflowOutFlowAddresses();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" height="100vh">
      {loading && (
        <>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          <Text
            color="var(--Text-Primary-default, #000)"
            textAlign="center"
            fontSize="30px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="38px"
          >
            We are fetching the data.
          </Text>
          <Text
            color="var(--Text-Primary-default, #909090)"
            textAlign="center"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="22px"
          >
            It can take some minutes, once done you will be redirected to the next step!
          </Text>
        </>
      )}
    </Box>
  );
}

export default InOutFlowLoadingScreen;
