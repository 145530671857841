import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Input,
  Flex,
  Heading,
  useToast,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import SettingsTable from '../components/SettingsTable';
import TokenAddressModal from '../components/modals/TokenAddressModal';
import HashTag from '../assets/images/pngs/Hashtag.png';
import Inflow from '../assets/images/pngs/Inflow.png';
import { ArrowRightOutlined } from '@ant-design/icons';
import axios from '../api/axios';

import Users from '../assets/images/pngs/Users.png';
import UserTransactions from '../assets/images/pngs/UserTransactions.png';

const Settings = () => {
  const [currentView, setCurrentView] = useState('main');
  const [pairAddress, setPairAddress] = useState('');
  const [isTokenAddressModalOpen, setIsTokenAddressModalOpen] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    inOutFlow: false,
    userTransactions: false,
    tokenHolders: false,
  });
  const [lastUpdateTimes, setLastUpdateTimes] = useState({
    inOutFlow: null,
    userTransactions: null,
    tokenHolders: null,
  });

  useEffect(() => {
    fetchLastUpdateTimes();
  }, []);

  const fetchLastUpdateTimes = async () => {
    try {
      const response = await axios.get('/api/user/account/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setLastUpdateTimes({
        inOutFlow: response.data.last_inflow_outflow_update,
        userTransactions: response.data.last_token_transactions_update,
        tokenHolders: response.data.last_tokenholders_update,
      });
    } catch (error) {
      console.error('Failed to fetch last update times:', error);
    }
  };

  const handleViewChange = (view: React.SetStateAction<string>) => {
    setCurrentView(view);
  };

  const handleAddPairAddress = async () => {
    try {
      const response = await axios.patch(
        '/api/user/account/',
        { pair_address: pairAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Pair address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setPairAddress('');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update pair address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTokenAddressSuccess = () => {
    setCurrentView('main');
  };
  const handleUpdate = async (endpoint: string, stateKey: keyof typeof loadingStates) => {
    setLoadingStates((prev: typeof loadingStates) => ({ ...prev, [stateKey]: true }));
    try {
      const response = await axios.post(
        `/api/user/${endpoint}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Update triggered successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        const now = new Date().toISOString();
        setLastUpdateTimes((prev) => ({ ...prev, [stateKey]: now }));

        await axios.patch(
          '/api/user/update-last-update-fields/',
          { [`last_${stateKey}_update`]: now },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      }
    } catch (error) {
      // toast({
      //   title: 'Error',
      //   description: 'Failed to trigger update',
      //   status: 'error',
      //   duration: 5000,
      //   isClosable: true,
      // });
    } finally {
      setLoadingStates((prev: typeof loadingStates) => ({ ...prev, [stateKey]: false }));
    }
  };

  const formatLastUpdateTime = (timestamp: string | null) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const BackButton = () => (
    <Flex
      alignItems="center"
      mb={4}
      cursor="pointer"
      onClick={() => handleViewChange('main')}
      bg="white"
      p={2}
      borderRadius="full"
      boxShadow="md"
      width="fit-content"
    >
      <ChevronLeftIcon color={'black'} />
      <Text ml={2} fontWeight="medium" color={'black'}>
        Back to Settings
      </Text>
    </Flex>
  );

  const boxStyles = {
    display: 'flex',
    padding: '12px', // Decreased padding
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    gap: '12px', // Decreased gap
    borderRadius: '20px',
    border: '1px solid rgba(0, 0, 0, 0.06)',
    background: '#FFF',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
    height: 'auto',
  };

  const tooltipStyles = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    color: '#000000',
    borderRadius: '8px',
    padding: '8px 12px',
    fontSize: '12px',
    fontWeight: '400',
    boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
    backdropFilter: 'blur(10px)',
  };

  return (
    <Box p={4}>
      {' '}
      {currentView === 'main' ? (
        <Flex gap={4} align="stretch">
          {' '}
          <VStack spacing={4} align="stretch" flex={0.75}>
            {' '}
            <Box {...boxStyles}>
              <Heading
                as="h1"
                fontSize="24px"
                fontWeight="500"
                fontStyle="normal"
                color="#000000E0"
                fontFamily="Poppins"
                lineHeight="24px"
              >
                Settings
              </Heading>
              <Text fontSize="md" color="#909090">
                You can always change the items you have set in here.
              </Text>

              <VStack spacing={2} align="stretch" width="100%">
                <Button
                  onClick={() => setIsTokenAddressModalOpen(true)}
                  variant="unstyled"
                  width="100%"
                  height="auto"
                  p={0}
                >
                  <Flex alignItems="center" width="100%">
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="40px"
                      height="40px"
                      borderRadius="50%"
                      mr={3}
                    >
                      <img src={HashTag} alt="HashTag" width="20px" height="20px" />
                    </Flex>
                    <Text
                      flex={1}
                      textAlign="left"
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight={400}
                      color="#000000E0"
                    >
                      Add your token address
                    </Text>
                    <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
                  </Flex>
                </Button>
                <Button
                  onClick={() => handleViewChange('inOutFlow')}
                  variant="unstyled"
                  width="100%"
                  height="auto"
                  p={0}
                >
                  <Flex alignItems="center" width="100%">
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="40px"
                      height="40px"
                      borderRadius="50%"
                      mr={3}
                    >
                      <img src={Inflow} alt="Inflow" width="20px" height="20px" />
                    </Flex>
                    <Text
                      flex={1}
                      textAlign="left"
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight={400}
                      color="#000000E0"
                    >
                      Manage your Inflow and Outflow Addresses
                    </Text>
                    <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
                  </Flex>
                </Button>
              </VStack>
            </Box>
            <Box {...boxStyles}>
              <Heading as="h2" size="md">
                Add your pair address
              </Heading>
              <Text
                color={'#000000E0'}
                fontFamily="Poppins"
                fontSize="24px"
                lineHeight={'24px'}
                fontWeight={500}
                fontStyle="normal"
              >
                Add your pair address
              </Text>
              <Text
                color={'#000000E0'}
                fontFamily="Poppins"
                fontSize="14px"
                lineHeight={'22px'}
                fontWeight={400}
                fontStyle="normal"
              >
                Pair Address
              </Text>
              <Input
                placeholder="Enter Pair Address"
                color={'#000000E0'}
                value={pairAddress}
                onChange={(e) => setPairAddress(e.target.value)}
                width="100%"
              />
              <Button background={'#2d8dfb'} colorScheme="blue" onClick={handleAddPairAddress} width="100%">
                Add
              </Button>
            </Box>
          </VStack>
          {/* New Box for Update Data */}
          <Box {...boxStyles} width={'620px'} height={'280px'}>
            {' '}
            {/* Changed width to 50% */}
            <Heading
              as="h1"
              fontSize="18px"
              fontWeight="500"
              fontStyle="normal"
              color="#000000E0"
              fontFamily="Poppins"
              lineHeight="24px"
            >
              Update Data
            </Heading>
            <Text textAlign="left" fontFamily="Poppins" fontSize="12px" fontWeight={400} color="#000000E0">
              Quickly update your information, and stay up to date
            </Text>
            <VStack spacing={2} align="stretch" width="100%">
              <Flex alignItems="center" width="100%">
                <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
                  <img src={Inflow} alt="HashTag" width="20px" height="20px" />
                </Flex>
                <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
                  Update In-Flow & Out-Flow Transactions
                </Text>

                <Tooltip
                  label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.inOutFlow)}`}
                  hasArrow
                  placement="top"
                  {...tooltipStyles}
                >
                  <Button
                    background={'#2d8dfb'}
                    colorScheme="blue"
                    onClick={() => handleUpdate('trigger-update-user-inflow-outflow/', 'inOutFlow')}
                    isLoading={loadingStates.inOutFlow}
                  >
                    Update
                  </Button>
                </Tooltip>
              </Flex>

              <Flex alignItems="center" width="100%">
                <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
                  <img src={UserTransactions} alt="HashTag" width="20px" height="20px" />
                </Flex>
                <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
                  Update User Transactions
                </Text>

                <Tooltip
                  label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.userTransactions)}`}
                  hasArrow
                  placement="top"
                  {...tooltipStyles}
                >
                  <Button
                    background={'#2d8dfb'}
                    colorScheme="blue"
                    onClick={() => handleUpdate('trigger-update-user-token-transactions/', 'userTransactions')}
                    isLoading={loadingStates.userTransactions}
                  >
                    Update
                  </Button>
                </Tooltip>
              </Flex>

              <Flex alignItems="center" width="100%">
                <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
                  <img src={Users} alt="HashTag" width="20px" height="20px" />
                </Flex>
                <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
                  Update Token Holders
                </Text>

                <Tooltip
                  label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.tokenHolders)}`}
                  hasArrow
                  placement="top"
                  {...tooltipStyles}
                >
                  <Button
                    background={'#2d8dfb'}
                    colorScheme="blue"
                    onClick={() => handleUpdate('trigger-update-user-tokenholders/', 'tokenHolders')}
                    isLoading={loadingStates.tokenHolders}
                  >
                    Update
                  </Button>
                </Tooltip>
              </Flex>
            </VStack>
          </Box>
        </Flex>
      ) : (
        <Box>
          <BackButton />
          {currentView === 'inOutFlow' && <SettingsTable />}
        </Box>
      )}
      <TokenAddressModal
        isShowModal={isTokenAddressModalOpen}
        setIsShowModal={setIsTokenAddressModalOpen}
        onSuccess={handleTokenAddressSuccess}
      />
      <Modal
        isOpen={Object.values(loadingStates).some(Boolean)}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {
          setIsLoading(false);
        }}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color={'#1f1f1f'}>
                Fetching Latest Data
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color={'#1f1f1f'} mt={4}>
                This may take some time, please be patient
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Settings;
