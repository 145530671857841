import { Box, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { Chart, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { Bar } from 'react-chartjs-2';
import { loadingStore } from '../../../store';
import configureAxios from '../../../AxiosClient';
import { unixTimeStampConversion } from '../../../utils/helper';
import axios from '../../../api/axios';

Chart.register(...registerables);

type AggregatedVolume = {
  timestamp: number;
  volume: number;
};

function BarChart() {
  const axiosInstance = configureAxios();
  const toast = useToast();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const [aggregatedVolume, setAggregatedVolume] = useState<AggregatedVolume[]>([]);

  const getAggregatedVolume = () => {
    axios
      .get('/api/token/aggregated-volume/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setAggregatedVolume(res.data);
          // toast({
          //   title: 'Status is ok',
          //   status: 'success',
          //   isClosable: true,
          // });
        } else {
          toast({
            title: 'Something went wrong while fetching data',
            status: 'success',
            isClosable: true,
          });
          setLoading(false);
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAggregatedVolume();
  }, []);

  const getDate = (timestamps: number[]) => {
    const result = timestamps.map((timestamp) => {
      const result = new Date(timestamp).toLocaleDateString('en-US');
      return result;
    });

    return result;
  };

  const data = {
    labels: aggregatedVolume.reverse().map((info) => unixTimeStampConversion(info.timestamp)),
    datasets: [
      {
        label: 'Transaction volume',
        backgroundColor: '#69B1FF',
        borderColor: '#3B87F7',
        borderWidth: 2,
        borderRadius: 14,
        barThickness: window.innerWidth > 960 ? 42 : 15,
        hoverBackgroundColor: '#3B87F7',
        hoverBorderColor: '#3B87F7',
        indexAxis: 'x',
        data: aggregatedVolume.map((info) => info.volume),
        barPercentage: 0.9,
        categoryPercentage: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        // ticks: {
        //   stepSize: 100, // Set the step size here
        // },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box>
      <Text className="text-item" color={'#000'} fontSize="20px" fontWeight={700} lineHeight="28px">
        Daily SHIDO Aggregated Volume
      </Text>
      <Box
        className="gsap-item"
        h="auto"
        mt="16px"
        borderRadius={'20px'}
        bg="#FFF"
        p={['12px', '12px', '24px']}
        minWidth={'100%'}
      >
        {loading ? (
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        ) : (
          <Bar data={data as any} options={options} />
        )}
      </Box>
    </Box>
  );
}
export default BarChart;
