import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const TagIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66675 2.5C3.82509 2.5 2.33342 3.98333 2.33342 5.80833V14.1917C2.33342 16.0167 3.82509 17.5 5.66675 17.5H15.6668C17.5084 17.5 19.0001 16.0167 19.0001 14.1917V5.80833C19.0001 3.98333 17.5084 2.5 15.6668 2.5H5.66675ZM9.67509 7.9C9.67509 8.95833 8.80842 9.825 7.75009 9.825C6.69175 9.825 5.82509 8.95833 5.82509 7.9C5.82509 6.84167 6.69175 5.975 7.75009 5.975C8.80842 5.975 9.67509 6.84167 9.67509 7.9ZM10.6668 14.0167H4.83342C4.71675 14.0167 4.60009 13.9667 4.52509 13.8833C4.45009 13.8 4.40842 13.6833 4.41675 13.5667C4.55842 12.1667 5.67509 11.0583 7.07509 10.925C7.51675 10.8833 7.97509 10.8833 8.41675 10.925C9.81675 11.0583 10.9418 12.1667 11.0751 13.5667C11.0918 13.6833 11.0501 13.8 10.9751 13.8833C10.9001 13.9667 10.7834 14.0167 10.6668 14.0167ZM14.8334 13.9583C14.4918 13.9583 14.2084 13.675 14.2084 13.3333C14.2084 12.9917 14.4918 12.7083 14.8334 12.7083H16.5001C16.8418 12.7083 17.1251 12.9917 17.1251 13.3333C17.1251 13.675 16.8418 13.9583 16.5001 13.9583H14.8334ZM13.1668 10.625C12.8251 10.625 12.5418 10.3417 12.5418 10C12.5418 9.65833 12.8251 9.375 13.1668 9.375H16.5001C16.8418 9.375 17.1251 9.65833 17.1251 10C17.1251 10.3417 16.8418 10.625 16.5001 10.625H13.1668ZM12.3334 7.29167C11.9918 7.29167 11.7084 7.00833 11.7084 6.66667C11.7084 6.325 11.9918 6.04167 12.3334 6.04167H16.5001C16.8418 6.04167 17.1251 6.325 17.1251 6.66667C17.1251 7.00833 16.8418 7.29167 16.5001 7.29167H12.3334Z"
    />
  </Icon>
);
