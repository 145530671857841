import {
  Box,
  Button,
  FormControl,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from '../../../api/axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CoveyLogoTextWhite from '../../../assets/images/svgs/coveyLogoTextWhite.svg';
import CoveyLogoWhite from '../../../assets/images/svgs/coveyLogoWhite.svg';
import { UserOutlined } from '../../../Icons/UserOutlined';
import { useDashboardStore, useLoginStore } from '../../../store';
import { PasswordField } from './PasswordField';

function LogInForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const setReplacedDashboard = useDashboardStore((state: any) => state.setReplacedDashboard);

  const handleLogin = () => {
    const userData = {
      email: username,
      password: password,
    };

    axios
      .post('/api/user/login/', userData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.data, 'response.data.data');
          localStorage.setItem('access_token', response.data.data.access_token);
          localStorage.setItem('refresh_token', response.data.data.refresh_token);
          setLoginDetails(response.data.data);

          setReplacedDashboard(false);
        }
      })
      .catch(() => {
        toast({
          title: 'Login error',
          description: 'Something went wrong while logging in',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      display={'flex'}
      justifyContent="center"
      alignItems={'center'}
      flexDirection="column"
      margin={['24px', '40px']}
      height={['calc(100% - 48px)']}
      bg={['#353535', '#353535']}
    >
      <Box display={'flex'} gap="10px">
        <Image src={CoveyLogoWhite} />
        <Image src={CoveyLogoTextWhite} />
      </Box>
      <Text color={'#fff'} fontSize="16px" fontWeight={600} mt="36px">
        Login to your admin dashboard
      </Text>
      <FormControl mt="32px">
        <InputGroup backgroundColor={'#494949'} color="#909090" border={'none'} borderRadius="12px">
          <InputRightElement pointerEvents="none">
            <UserOutlined />
          </InputRightElement>
          <Input placeholder="Admin" value={username} onChange={(e) => setUsername(e.target.value)} />
        </InputGroup>
        <PasswordField password={password} setPassword={setPassword} />
      </FormControl>
      <Button
        background={'#4096FF'}
        color={'white'}
        _hover={{
          bg: '#494949',
          color: 'white',
        }}
        _active={{
          bg: '#4096FF',
          color: 'white',
        }}
        textTransform="capitalize"
        fontSize={['14px']}
        fontWeight={500}
        fontStyle="normal"
        width="inherit"
        transition="0.2s all"
        mt="24px"
        w="100%"
        h="46px"
        onClick={handleLogin}
      >
        <span>Login</span>
      </Button>
      <Box w="100%" display="flex" justifyContent="space-between" mt="16px">
        <RadioGroup>
          <Radio colorScheme="blue" value="1" color={'#909090'}>
            Remember me
          </Radio>
        </RadioGroup>
        <Text cursor="pointer" color={'#909090'} onClick={() => navigate('/sign-up')}>
          Sign up
        </Text>
      </Box>
    </Box>
  );
}
export default LogInForm;
