import { AddIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';
import { Button as AntButton, Pagination, Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import AddressBookEditModal from '../components/modals/AddressBookEditModal';
import DeleteModal from '../components/modals/DeleteModal';
import { useLoginStore } from '../store';

export interface DataType {
  id: number;
  wallet_address: string;
  name: string;
  created_at: string;
  updated_at: string;
  balance: number;
}

interface PaginatedApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: DataType[];
}

interface ApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: DataType[];
}

import { Input } from 'antd';
const { Search } = Input;

const AddressBook: React.FC = () => {
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState<DataType | null>(null);
  const [deletingRecord, setDeletingRecord] = useState<DataType | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const savedPage = localStorage.getItem('addressBooksPageIndex');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [pageSize, setPageSize] = useState<number>(() => {
    const savedSize = localStorage.getItem('addressBooksPageSize');
    return savedSize ? parseInt(savedSize, 10) : 10;
  });
  const navigate = useNavigate();
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize, searchQuery]);

  useEffect(() => {
    localStorage.setItem('addressBooksPageIndex', currentPage.toString());
    localStorage.setItem('addressBooksPageSize', pageSize.toString());
  }, [currentPage, pageSize]);

  const fetchData = async (page: number, size: number) => {
    try {
      const response = await axios.get<PaginatedApiResponse>(
        `/api/user/address-book/?page=${page}&page_size=${size}&search=${searchQuery}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );
      const sortedData = [...response.data.results].sort((a, b) => b.balance - a.balance);
      setTableData(sortedData);
      setTotalItems(response.data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const navigateToSinglePage = (address: string) => {
    setSelectedWalletAddress(address);
    navigate(`/addressBooks/${address}`, {
      state: {
        address: address,
        labels: [],
      },
    });
  };

  const handleEdit = (record: DataType) => {
    setEditingRecord(record);
    setIsAddingNew(false);
    setIsShowSuccessModal(true);
  };

  const handleDelete = (record: DataType) => {
    setDeletingRecord(record);
    setIsShowDeleteModal(true);
  };

  const handleAdd = () => {
    setEditingRecord(null);
    setIsAddingNew(true);
    setIsShowSuccessModal(true);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
    localStorage.setItem('addressBooksPageIndex', '1');
  };

  const handleSaveChanges = async (updatedRecord: DataType) => {
    try {
      if (isAddingNew) {
        await axios.put(
          '/api/user/address-book/',
          {
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      } else {
        await axios.patch(
          '/api/user/address-book/',
          {
            id: updatedRecord.id,
            wallet_address: updatedRecord.wallet_address,
            name: updatedRecord.name,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      }
      fetchData(currentPage, pageSize);
      setIsShowSuccessModal(false);
      setEditingRecord(null);
      setIsAddingNew(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingRecord) {
      try {
        await axios.delete('/api/user/address-book/', {
          data: { id: deletingRecord.id },
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        fetchData(currentPage, pageSize);
        setIsShowDeleteModal(false);
        setDeletingRecord(null);
      } catch (error) {
        console.error('Error deleting record:', error);
      }
    }
  };

  const handlePageChange = (page: number, size?: number) => {
    setCurrentPage(page);
    if (size) setPageSize(size);
    localStorage.setItem('addressBooksPageIndex', page.toString());
    localStorage.setItem('addressBooksPageSize', size ? size.toString() : pageSize.toString());
  };

  const getUniqueFilters = (data: DataType[], key: keyof DataType) => {
    const uniqueValues = Array.from(new Set(data.map((item) => item[key])));
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns: ColumnType<DataType>[] = [
    {
      title: 'Address',
      dataIndex: 'wallet_address',
      key: 'wallet_address',
      sorter: (a, b) => a.wallet_address.localeCompare(b.wallet_address),
      render: (text: string) => (
        <AntButton
          type="link"
          onClick={() => navigateToSinglePage(text)}
          style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
        >
          {`${text}`}
        </AntButton>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      sorter: (a, b) => b.balance - a.balance,
      defaultSortOrder: 'ascend',
      render: (balance: any) => {
        const numBalance = Number(balance);
        return isNaN(numBalance) ? '0.00' : numBalance.toFixed(2);
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filters: getUniqueFilters(tableData, 'name'),
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <AntButton
            type="link"
            onClick={() => handleEdit(record)}
            style={{ color: '#2D8DFB', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Edit
          </AntButton>
          <AntButton
            type="link"
            onClick={() => handleDelete(record)}
            style={{ color: '#FF4D4F', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '400' }}
          >
            Delete
          </AntButton>
        </Space>
      ),
    },
  ];

  return (
    <Box marginTop="40px">
      <Text
        color="rgba(0, 0, 0, 0.88)"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="22px"
        marginBottom="8px"
      >
        My Address Book
      </Text>
      <Search
        placeholder="Search by wallet address or name"
        allowClear
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
        style={{ maxWidth: '500px', marginBottom: '16px' }}
      />
      <AntButton type="primary" icon={<AddIcon />} onClick={handleAdd} style={{ marginBottom: '16px' }}>
        Add New Address
      </AntButton>
      <Table columns={columns} dataSource={tableData} rowKey="id" pagination={false} />
      <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
        pageSizeOptions={['10', '50', '100']}
        style={{ marginTop: '16px', textAlign: 'right' }}
      />
      <AddressBookEditModal
        isShowModal={isShowSuccessModal}
        setIsShowModal={setIsShowSuccessModal}
        editingRecord={editingRecord}
        onSaveChanges={handleSaveChanges}
        isAddingNew={isAddingNew}
      />
      <DeleteModal
        showModal={isShowDeleteModal}
        isShowModal={setIsShowDeleteModal}
        onConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};

export default AddressBook;
