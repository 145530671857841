import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const WalletNew = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    minW="24px"
    minH="24px"
    boxSize="24px"
    fill="none"
    {...props}
  >
    <path d="M22.2504 15.9688C22.2504 18.9288 20.1904 21.4087 17.4204 22.0587L17.6904 21.6088C17.9004 21.2488 17.7904 20.7887 17.4304 20.5787C17.0804 20.3687 16.6104 20.4787 16.4004 20.8387L15.3504 22.5887C15.2104 22.8187 15.2104 23.1087 15.3404 23.3387C15.4704 23.5787 15.7204 23.7187 15.9904 23.7187C20.2604 23.7187 23.7404 20.2387 23.7404 15.9688C23.7504 15.5588 23.4104 15.2188 23.0004 15.2188C22.5904 15.2188 22.2504 15.5588 22.2504 15.9688Z" />
    <path d="M0.25 7.96875C0.25 8.37875 0.59 8.71875 1 8.71875C1.41 8.71875 1.75 8.37875 1.75 7.96875C1.75 5.00875 3.81 2.52875 6.58 1.87875L6.31 2.32875C6.1 2.68875 6.21 3.14875 6.57 3.35875C6.92 3.56875 7.39 3.45875 7.6 3.09875L8.65 1.34875C8.78 1.11875 8.79 0.83875 8.65 0.59875C8.52 0.36875 8.27 0.21875 8 0.21875C3.73 0.21875 0.25 3.69875 0.25 7.96875Z" />
    <path d="M16.3302 14.76C16.3302 14.75 16.3302 14.75 16.3302 14.74C16.0402 11.01 13.0202 7.97 9.24023 7.67C9.71023 4.74 12.2502 2.5 15.3302 2.5C18.7402 2.5 21.5002 5.26 21.5002 8.67C21.5002 11.75 19.2602 14.29 16.3302 14.76Z" />
    <path d="M9.42914 13.8203H7.36914V14.5703H9.42914C9.63914 14.5803 9.80914 14.4103 9.80914 14.2003C9.80914 14.0503 9.63914 13.8203 9.42914 13.8203Z" />
    <path d="M9.42914 16.0781H7.36914V16.8281H9.83914C10.1591 16.8281 10.3791 16.6281 10.3791 16.4481C10.3791 16.2681 10.1491 16.0781 9.82914 16.0781H9.42914Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15 9.16844C8.99 9.15844 8.84 9.14844 8.67 9.14844C5.26 9.14844 2.5 11.9084 2.5 15.3284C2.5 18.7384 5.26 21.4984 8.67 21.4984C12.08 21.4984 14.85 18.7384 14.85 15.3284C14.85 15.1584 14.84 15.0084 14.83 14.8484C14.6 11.8184 12.18 9.39844 9.15 9.16844ZM9.17 18.3284V18.7084C9.17 19.1184 8.83 19.4584 8.42 19.4584C8.01 19.4584 7.67 19.1184 7.67 18.7084V18.3284H6.62C6.21 18.3284 5.87 17.9884 5.87 17.5784V15.3284V13.0784C5.87 12.6684 6.21 12.3284 6.62 12.3284H7.67V11.9484C7.67 11.5384 8.01 11.1984 8.42 11.1984C8.83 11.1984 9.17 11.5384 9.17 11.9484V12.3284H9.43C10.43 12.3284 11.31 13.2084 11.31 14.2084C11.31 14.4984 11.24 14.7684 11.12 15.0184C11.58 15.3584 11.87 15.8784 11.87 16.4584C11.87 17.4884 10.96 18.3284 9.83 18.3284H9.17Z"
    />
  </Icon>
);
