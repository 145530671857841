import {
  Box,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CalendarIconOutlined from '../../assets/images/svgs/CalendarOutlined.svg';

interface DatePickerProps {
  onDateChange: (date: Date | null) => void;
  selectedDate: Date | null;
  validDates?: string[];
}

const DatePicker: React.FC<DatePickerProps> = ({ onDateChange, selectedDate, validDates = [] }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleDateChange = (value: Date): void => {
    onDateChange(value);
    onClose();
  };

  const today = new Date();
  const minDate = new Date('2024-11-07'); // Set minimum date to November 7th

  // Convert validDates strings to Date objects
  const validDateObjects = validDates.map((dateStr) => new Date(dateStr));

  // Function to check if a date is enabled
  const tileDisabled = ({ date }: { date: Date }): boolean => {
    if (validDates.length === 0) return false;

    return !validDateObjects.some(
      (validDate) =>
        validDate.getFullYear() === date.getFullYear() &&
        validDate.getMonth() === date.getMonth() &&
        validDate.getDate() === date.getDate(),
    );
  };

  return (
    <Box>
      <Popover isOpen={isOpen} onClose={onClose} placement="bottom-end" offset={[0, 4]}>
        <PopoverTrigger>
          <InputGroup
            border={'var(--Notification-lineHeightLG, 1px) solid var(--Text-action, #4096FF)'}
            borderRadius={'6px'}
            boxShadow={'0px 0px 0px 2px rgba(23, 120, 255, 0.15)'}
            size="md"
            variant="outline"
            width="140px"
            padding={'var(--Notification-lineHeightLG, 0px) var(--DatePicker-controlPaddingHorizontal, 12px)'}
          >
            <Input
              placeholder="Select date"
              value={selectedDate ? format(selectedDate, 'MMM dd, yyyy') : ''}
              readOnly
              onClick={onToggle}
              bg="white"
              fontSize="14px"
              color="var(--DatePicker-colorTextDisabled, rgba(0, 0, 0, 0.25))"
              fontWeight={400}
              lineHeight={'22px'}
              height="36px"
              transition="all 0.2s"
              cursor="pointer"
              textAlign="left"
              border={'none'}
              padding="0px"
            />
            <InputRightElement>
              <Image src={CalendarIconOutlined} />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>

        <PopoverContent
          p="0"
          w="323px"
          borderRadius="xl"
          boxShadow="lg"
          bg="white"
          border="1px solid red"
          borderColor="gray.100"
          _focus={{ outline: 'none' }}
        >
          <Box
            className="calendar-wrapper"
            sx={{
              '.react-calendar': {
                width: '100%',
                p: '4',
                fontFamily: 'inherit',
                border: '1px solid #E9E9E9',
              },
              '.react-calendar__navigation': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '3',
                '& button': {
                  padding: '4px 8px',
                  background: 'none',
                  border: 'none',
                  color: 'gray.800',
                },
              },
              '.react-calendar__navigation__label': {
                fontWeight: '600',
                fontSize: '14px',
                flex: 'auto',
                textAlign: 'center',
              },
              '.react-calendar__navigation__arrow': {
                fontSize: '20px',
                color: 'gray.600',
                cursor: 'pointer',
                '&:hover': {
                  color: 'blue.500',
                },
                '&:disabled': {
                  color: 'gray.300',
                },
              },
              '.react-calendar__month-view__weekdays': {
                color: 'gray.600',
                fontWeight: '600',
                fontSize: '12px',
                textTransform: 'uppercase',
                textAlign: 'center',
                paddingBottom: '8px',
                abbr: {
                  textDecoration: 'none',
                },
              },
              '.react-calendar__tile': {
                padding: '8px',
                border: 'none',
                borderRadius: 'full',
                fontSize: '14px',
                fontWeight: '400',
                color: 'gray.800',
                transition: 'all 0.2s',
                '&:hover': {
                  background: 'blue.50',
                },
                '&:disabled': {
                  color: 'gray.300',
                  cursor: 'not-allowed',
                  '&:hover': {
                    background: 'transparent',
                  },
                },
              },
              '.react-calendar__tile--now': {
                background: 'none',
                color: 'blue.500',
                fontWeight: '600',
              },
              '.react-calendar__tile--active': {
                color: 'rgba(0, 0, 0, 0.88) !important',
                fontWeight: '600',
                borderRadius: '6px',
                border: 'var(--Notification-lineHeightLG, 1px) solid var(--Text-action, #4096FF)',
              },
              '.react-calendar__month-view__days__day--neighboringMonth': {
                color: 'gray.300',
              },
            }}
          >
            <Calendar
              onChange={(value) => handleDateChange(value as Date)}
              value={selectedDate || today}
              minDetail="month"
              className="react-calendar"
              prevLabel="«"
              nextLabel="»"
              prev2Label={null}
              next2Label={null}
              showNeighboringMonth={true}
              minDate={minDate} // Set minimum date to November 7th
              maxDate={today} // Set maximum date to today
              tileDisabled={tileDisabled}
            />
            <Box borderTop="1px solid" borderColor="gray.100" p="2" textAlign="center">
              <Box
                as="button"
                fontSize="14px"
                color="blue.500"
                fontWeight="500"
                onClick={() => handleDateChange(today)}
                _hover={{ color: 'blue.600' }}
              >
                Today
              </Box>
            </Box>
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DatePicker;
