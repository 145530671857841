import { Box } from '@chakra-ui/react';
import React from 'react';

interface TransferTypeFilterProps {
  activeFilter: string;
  onFilterChange: (type: string) => void;
}

const TransferTypeFilter: React.FC<TransferTypeFilterProps> = ({ activeFilter, onFilterChange }) => {
  const filterTypes = [
    { label: 'All', value: 'all' },
    { label: 'Receive', value: 'Receive' },
    { label: 'Send', value: 'Send' },
  ];

  return (
    <Box
      display="flex"
      padding="2px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      background="var(--Neutrals-2, #F6F6F6)"
      marginRight="16px"
    >
      {filterTypes.map(({ label, value }) => (
        <Box
          key={value}
          display="flex"
          padding="8px 16px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="5px"
          background={activeFilter === value ? '#FFF' : 'transparent'}
          cursor="pointer"
          onClick={() => onFilterChange(value)}
          transition="background 0.2s"
          _hover={{
            background: activeFilter === value ? '#FFF' : 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <div
            style={{
              color: activeFilter === value ? '#4096FF' : 'var(--Text-Secondary-description, #909090)',
              fontFamily: 'VisbyCF',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {label}
          </div>
        </Box>
      ))}
    </Box>
  );
};

export default TransferTypeFilter;
