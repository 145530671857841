import { Box, Spinner, useToast, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import configureAxios from '../../../AxiosClient';
import { GraphInfo } from '../../../interfaces';
import { loadingStore } from '../../../store';
import { GraphComponent } from './Graph';
import axios from '../../../api/axios';

function TotalVolume() {
  const axiosInstance = configureAxios();
  const toast = useToast();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const [graphInfo, setGraphInfo] = useState<GraphInfo>();
  const loading = loadingStore((state: any) => state.loading);
  const mexcInfo = loadingStore((state: any) => state.mexcInfo);

  const getGraphData = async () => {
    try {
      const volumeResponse = await axios.get('/api/token/volume/');

      if (volumeResponse.status === 200) {
        setGraphInfo(volumeResponse.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      // toast({
      //   title: 'There was an error fetching data',
      //   status: 'error',
      //   isClosable: true,
      // });
    }
  };

  useEffect(() => {
    setLoading(true);
    getGraphData();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      ) : (
        <Box
          bg="white"
          w="inherit"
          mt="16px"
          display={'flex'}
          flexDirection="column"
          borderRadius="20px"
          padding="23px 24px 15px 23px"
          className="gsap-item"
          flex={1}
        >
          <Flex flexWrap="wrap" gap={'20px'} justifyContent={'space-between'}>
            {graphInfo && graphInfo?.dex_chart.length > 0 && (
              <GraphComponent
                textMain={'Dex Volume'}
                price={`$${graphInfo?.dex_value}K`}
                textColor={'#FF4D4F'}
                graphBorderColor="#FF7875"
                gradientTopColor="#FF7875"
                gradientBottomColor="rgba(199, 240, 219, 0.00)"
                graphData={graphInfo?.dex_chart.map((info) => info.value)}
                timeStamps={graphInfo?.dex_chart.map((info) => info.timestamp)}
                showGraph={true}
              />
            )}
            {graphInfo && graphInfo?.mexc_chart.length > 0 && (
              <GraphComponent
                textMain={'MEXC Volume'}
                price={
                  mexcInfo?.current_volume &&
                  mexcInfo?.current_volume !== '' &&
                  `$${mexcInfo?.current_volume.toFixed(2)}K`
                }
                textColor={'#FF4D4F'}
                graphBorderColor="#FF7875"
                gradientTopColor="#FF7875"
                gradientBottomColor="rgba(199, 240, 219, 0.00)"
                graphData={graphInfo && graphInfo?.mexc_chart.map((info) => info.volume)}
                timeStamps={graphInfo && graphInfo?.mexc_chart.map((info) => info.timestamps)}
                showGraph={true}
              />
            )}
          </Flex>
        </Box>
      )}
    </>
  );
}

export default TotalVolume;
