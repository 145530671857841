import React from 'react';
import { Box, Button, Text, Flex } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import axios from '../../../../api/axios';
import { loadingStore } from '../../../../store';

function ProjectFundingForm() {
  const toast = useToast();
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);

  const handleFormSubmit = async (isProjectFunded: boolean) => {
    try {
      await axios.patch(
        '/api/user/account/',
        {
          is_project_funded: isProjectFunded,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );
      setStepNumber(3);
      toast({
        title: 'Success',
        description: 'Project funding status successfully added',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong while adding project funding status',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" height="inherit">
      <Text
        color="var(--Text-Primary-default, #000)"
        textAlign="center"
        fontSize="30px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="38px"
        marginBottom="24px"
      >
        Before we start, fill in your details!
      </Text>
      <Box
        borderRadius="20px"
        border="1px solid rgba(0, 0, 0, 0.06)"
        background="#FFF"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
        display="flex"
        width="401px"
        padding="24px"
        flexDirection="column"
      >
        <Text
          color="rgba(0, 0, 0, 0.88)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="24px"
          marginTop="24px"
          marginBottom="24px"
        >
          Is your project funded yet?
        </Text>
        <Flex justifyContent="space-between" mt="24px">
          <Button
            background="white"
            color="#4096FF"
            border="3px solid #F0F0F0"
            _hover={{
              bg: '#F0F0F0',
              color: '#4096FF',
            }}
            _active={{
              bg: '#E8E8E8',
              color: '#4096FF',
            }}
            textTransform="capitalize"
            fontSize="14px"
            fontWeight={400}
            fontStyle="normal"
            width="48%"
            transition="0.2s all"
            h="46px"
            onClick={() => handleFormSubmit(false)}
          >
            No
          </Button>
          <Button
            background="#4096FF"
            color="white"
            _hover={{
              bg: '#3A87E8',
            }}
            _active={{
              bg: '#3479D1',
            }}
            textTransform="capitalize"
            fontSize="14px"
            fontWeight={400}
            fontStyle="normal"
            width="48%"
            transition="0.2s all"
            h="46px"
            onClick={() => handleFormSubmit(true)}
          >
            Yes
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default ProjectFundingForm;
