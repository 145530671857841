import { Box, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { gsap } from 'gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Content_HeaderTL } from '../../assets/animation/Content_Header';
import ArrowDown from '../../assets/images/svgs/ArrowDown.svg';
import CoveyInsightMobileLogo from '../../assets/images/svgs/coveyInsightMobileLogo.svg';
import HamBurgerMenuIconMobile from '../../assets/images/svgs/hamBurgerMenuIconMobile.svg';
import headerCalender from '../../assets/images/svgs/headerCalender.svg';
import NotificationIcon from '../../assets/images/svgs/notification.svg';
import profileImage from '../../assets/images/svgs/profileImage.svg';
import { Account } from '../../Icons/AccountIcon';
import { SidebarProps } from '../../interfaces';

interface DropDownMenuProps {
  mobileView?: boolean;
}

interface MenuOptions {
  key: string;
  text: string;
}

const DropDownMenu = ({ mobileView }: DropDownMenuProps) => {
  const menuOptions: MenuOptions[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
    },
    {
      key: 'portfolio',
      text: 'Portfolio',
    },
    {
      key: 'marketOverview',
      text: 'Market Overview',
    },
    {
      key: 'wallet',
      text: 'Wallet',
    },
    {
      key: 'transactions',
      text: 'Transactions',
    },
    {
      key: 'reports',
      text: 'Reports',
    },
    {
      key: 'insight',
      text: 'Insight',
    },
  ];

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          {mobileView ? (
            <MenuButton
              h={['30px', '30px', '44px', '44px', '44px']}
              w={['30px', '30px', '44px', '44px', '44px']}
              borderRadius="50%"
              backgroundColor="#353535"
              display={['flex', 'flex', 'none']}
              position="relative"
            >
              <Account position="absolute" top="3px" right={'-17px'} />
            </MenuButton>
          ) : (
            <MenuButton
              display={['none', 'none', 'block', 'flex', 'flex']}
              borderLeft="1px solid rgba(0, 0, 0, 0.05)"
              fontSize="16px"
              fontStyle={'normal'}
              width={'fit-content'}
              fontWeight="500px"
              color="#909090"
              flexDirection={'row'}
              height={'100%'}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingLeft: '12px' }}>
                <Image src={profileImage} width={'44px'} height={'44px'} />
                <Text
                  color="var(--Text-Primary-default, #000)"
                  fontFamily="Poppins"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="38px"
                >
                  Degen
                </Text>
                <Image src={ArrowDown} width={'21px'} height={'21px'} />
              </div>
            </MenuButton>
          )}
          <MenuList bg="#353535" borderRadius="10px">
            {menuOptions.map((sideBarOption: MenuOptions) => (
              <MenuItem
                key={sideBarOption.key}
                _hover={{
                  bg: '#494949',
                  color: 'white',
                }}
                color="#909090"
                zIndex={1}
              >
                {sideBarOption?.text}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

function Header({ sideBarOpen, setSideBarOpen }: SidebarProps) {
  const location = useLocation();
  const Content_HeaderRef = useRef<HTMLDivElement>(null);
  const [isTokenHolderPage, setIsTokenHolderPage] = useState(false);
  const currentDate = format(new Date(), 'EEEE, dd MMMM');

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      Content_HeaderTL(Content_HeaderRef.current);
    }, Content_HeaderRef);

    return () => ctx.revert();
  }, [Content_HeaderRef]);

  useEffect(() => {
    if (pathname === '/tokenHolders') {
      setIsTokenHolderPage(true);
    }
  }, []);

  const { pathname } = location;

  return (
    <Box
      padding={'12px 24px'}
      minW={['unset', 'unset', 'unset', 'unset', '1070px']}
      // height={['44px']}
      w={['100%']}
      borderBottom="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ref={Content_HeaderRef}
      zIndex={1}
      className="content-header"
      background={'#FFF'}
      boxShadow={'0px 1px 6px 0px rgba(0, 0, 0, 0.12)'}
    >
      <Box className="gsap-item-left" display="flex" alignItems="center" gap={['8px', '16px', '16px', '16px', '16px']}>
        <Box
          h={['30px', '44px', '44px', '44px', '44px']}
          w={['30px', '44px', '44px', '44px', '44px']}
          borderRadius="50%"
          backgroundColor="#FFFFFF"
          display={['flex', 'flex', 'none', 'none', 'none']}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSideBarOpen(!sideBarOpen)}
          cursor="pointer"
        >
          <Image
            h={['22px', '22px', '44px', '44px', '44px']}
            w={['22px', '22px', '44px', '44px', '44px']}
            src={HamBurgerMenuIconMobile}
            alt="logo"
          />
        </Box>
        <Image
          h={['64px', '64px', '0', '0', '0']}
          w={['64px', '64px', '0', '0', '0']}
          src={CoveyInsightMobileLogo}
          alt="logo"
        />
        <Box
          display="flex"
          padding="10px"
          alignItems="center"
          gap="10px"
          borderRadius="100px"
          background="var(--Neutrals-2, #F6F6F6)"
        >
          <Image
            h={['0', '0', '44px', '24px', '24px']}
            w={['0', '0', '44px', '24px', '24px']}
            src={headerCalender}
            alt="logo"
          />
        </Box>
        <Text
          color="var(--Text-Primary-default, #000)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="28px"
        >
          {currentDate}
        </Text>

        {/* {isTokenHolderPage && (
          <>
            <Box borderLeft={'1px solid #000000'} height="32px"></Box>
            <Box>
              <Text fontSize={'20px'} fontWeight="700" color="black">
                Token Holders
              </Text>
            </Box>
          </>
        )} */}
      </Box>
      <Box
        className="gsap-item-right"
        display="flex"
        // h="280px"
        alignItems="center"
        gap={['8px', '16px', '16px', '12px', '12px']}
      >
        {/* <div className="gsap-item-inner"> */}
        <Box
          h={['22px', '44px', '44px', '44px', '44px']}
          w={['22px', '44px', '44px', '44px', '44px']}
          borderRadius="50%"
          backgroundColor="#FFFFFF"
          display={'flex'}
          justifyContent="center"
          alignItems="center"
        >
          {/* <Image src={profileImage} width={'42px'} height={'42px'} /> */}
          <Box
            display="flex"
            padding="10px"
            alignItems="center"
            gap="10px"
            borderRadius="100px"
            background="var(--Neutrals-2, #F6F6F6)"
          >
            <Image src={NotificationIcon} width={'24px'} height={'24px'} />{' '}
          </Box>
        </Box>
        {/* </div> */}
        {/* <div className="gsap-item-inner"> */}
        <DropDownMenu />
        <DropDownMenu mobileView={true} />
        {/* </div> */}
      </Box>
    </Box>
  );
}

export default Header;
