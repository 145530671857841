import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const LeftArrow = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.416 5.17301C5.71318 5.17301 5.03914 5.4522 4.54217 5.94917C4.0452 6.44614 3.766 7.12018 3.766 7.82301V16.655C3.766 17.3578 4.0452 18.0319 4.54217 18.5288C5.03914 19.0258 5.71318 19.305 6.416 19.305H7.877V5.17301H6.416ZM6.416 3.40601C5.2448 3.40601 4.12158 3.87126 3.29342 4.69942C2.46526 5.52758 2 6.65081 2 7.82201V16.655C1.99987 17.235 2.114 17.8094 2.33586 18.3452C2.55773 18.8811 2.88299 19.3681 3.29306 19.7782C3.70314 20.1884 4.19 20.5138 4.72584 20.7358C5.26168 20.9577 5.836 21.072 6.416 21.072H17.584C18.1641 21.072 18.7385 20.9577 19.2744 20.7357C19.8103 20.5136 20.2972 20.1882 20.7073 19.7779C21.1174 19.3676 21.4426 18.8806 21.6644 18.3445C21.8862 17.8085 22.0003 17.2341 22 16.654V7.82201C22 6.65081 21.5347 5.52758 20.7066 4.69942C19.8784 3.87126 18.7552 3.40601 17.584 3.40601H6.416ZM9.644 5.17301V19.305H17.584C18.2868 19.305 18.9609 19.0258 19.4578 18.5288C19.9548 18.0319 20.234 17.3578 20.234 16.655V7.82201C20.234 7.11918 19.9548 6.44514 19.4578 5.94817C18.9609 5.4512 18.2868 5.17201 17.584 5.17201H9.644V5.17301Z"
      fill="#909090"
    />
  </Icon>
);
