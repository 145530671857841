// api.ts

import axios from '../axios';

export interface ApiResponse<T> {
  data: T;
  error?: string;
}

export interface TransactionParams {
  page?: number;
  page_size?: number;
  sort?: 'asc' | 'desc';
  start_date?: string;
  end_date?: string;
  inflow_outflow_title?: string;
}

export interface BlockDataParams {
  time_range: string;
  start_date?: string;
  end_date?: string;
}

export interface SankeyParams {
  time_range?: string;
  start_date?: string;
  end_date?: string;
  origin_titles?: string[];
  destination_titles?: string[];
}

export interface SankeyParamsSingle {
  time_range?: string;
  start_date?: string;
  end_date?: string;
  inflow_outflow_title: string;
}
export interface WalletTransactionParams {
  address: string;
  page?: number;
  page_size?: number;
}

const handleApiError = (error: any): string => {
  console.error('API Error:', error);
  if (error.response?.status === 401) {
    return 'Your session has expired. Please log in again.';
  }
  return error.response?.data?.detail || 'An error occurred while fetching data';
};

export const api = {
  getUserTransactions: async (params: TransactionParams): Promise<ApiResponse<any>> => {
    try {
      const response = await axios.get(`/api/user/user-transactions/`, { params });
      return { data: response.data };
    } catch (error) {
      return { data: null, error: handleApiError(error) };
    }
  },

  getBlockData: async (params: BlockDataParams): Promise<ApiResponse<any>> => {
    try {
      const response = await axios.get(`/api/user/block-data/`, { params });
      return { data: response.data };
    } catch (error) {
      return { data: null, error: handleApiError(error) };
    }
  },

  getSankeyTransactions: async (params: SankeyParams): Promise<{ data: any; error?: string }> => {
    try {
      const response = await axios.get('/api/user/sankey-transactions/', {
        params: {
          ...params,
          origin_titles: params.origin_titles?.join(','),
          destination_titles: params.destination_titles?.join(','),
        },
      });
      return { data: response.data };
    } catch (error) {
      console.error('Error fetching Sankey transactions:', error);
      return { data: null, error: 'Failed to fetch Sankey transactions' };
    }
  },
  getSankeyTransactionsSingle: async (params: SankeyParamsSingle): Promise<{ data: any; error?: string }> => {
    try {
      const response = await axios.get('/api/user/sankey-transactions-single/', { params });
      return { data: response.data };
    } catch (error) {
      console.error('Error fetching Sankey transactions (single):', error);
      return { data: null, error: 'Failed to fetch Sankey transactions' };
    }
  },

  getInflowOutflowTitles: async (): Promise<ApiResponse<string[]>> => {
    try {
      const response = await axios.get(`/api/user/user-transactions/`);
      return { data: ['All', ...response.data.inflow_outflow_titles] };
    } catch (error) {
      return { data: [], error: handleApiError(error) };
    }
  },
  getWalletTransactions: async (params: WalletTransactionParams): Promise<ApiResponse<any>> => {
    try {
      const response = await axios.get(`/api/user/tokenholderTransactions/`, { params });
      return { data: response.data };
    } catch (error) {
      return { data: null, error: handleApiError(error) };
    }
  },
};

export default api;
