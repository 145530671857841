import { Box, Button, Divider, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { loadingStore } from '../../../../store';
import { Controller, useForm } from 'react-hook-form';
import { InOutFlowDataType } from '../../../../interfaces';
import { CrossIcon } from '../../../../Icons/CrossIcon';
import { AddIcon } from '@chakra-ui/icons';
import AddressDropDown from './AdressDropDown';
import axios from '../../../../api/axios';

interface AddressEntry {
  title: string;
  address: string;
  is_receiver: boolean;
}

function InOutFlow() {
  const toast = useToast();
  const setStepNumber = loadingStore((state: any) => state.setStepNumber);
  const [showForm, setShowForm] = useState(false);
  const [allAddresses, setAllAddresses] = useState<Record<string, AddressEntry>>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<InOutFlowDataType>({
    defaultValues: {
      title: '',
      address: '',
      is_receiver: '1',
    },
  });

  const handleFormSubmit = async (data: InOutFlowDataType) => {
    const inOutFlowData = {
      title: data?.title,
      address: data?.address,
      is_receiver: data?.is_receiver === '1' ? false : true,
    };
    axios
      .put('/api/user/settings/inflow-outflow-addresses/', inOutFlowData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        if (response) {
          getAllAddresses();
          setShowForm(false);
          reset();
          toast({
            title: 'Success',
            description: 'Token address successfully added',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Something went wrong.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const getAllAddresses = async () => {
    axios
      .get('/api/user/settings/inflow-outflow-addresses/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        if (response) {
          setAllAddresses(response?.data);
        }
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Something went wrong while fetching addresses',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    getAllAddresses();
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        justifyContent="center"
        alignItems="center"
        height="inherit"
        paddingBottom="20px"
      >
        <Text
          color="var(--Text-Primary-default, #000)"
          textAlign="center"
          fontSize="30px"
          fontStyle="normal"
          fontWeight=" 700"
          lineHeight="38px"
          marginBottom="24px"
          marginTop="49px !important"
        >
          Kindly fill in this information
        </Text>
        <Box
          borderRadius=" 20px"
          border=" 1px solid var(--colorSplit, rgba(0, 0, 0, 0.06))"
          background=" var(--Cascader-colorWhite, #FFF)"
          box-shadow=" 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
          display="flex"
          width="401px"
          padding="24px"
          flexDirection="column"
          maxHeight="700px"
          overflowY="scroll"
        >
          <Text
            color="rgba(0, 0, 0, 0.88)"
            textAlign="center"
            fontFamily="Poppins"
            fontSize=" 24px"
            fontStyle=" normal"
            fontWeight=" 500"
            lineHeight="24px"
            marginTop="24px"
            marginBottom="24px"
          >
            Add your inflow and outflow addresses
          </Text>
          <Box>
            {allAddresses &&
              Object.entries(allAddresses).map(([key, value]) => (
                <AddressDropDown
                  key={key}
                  setShowForm={setShowForm}
                  showForm={showForm}
                  title={value.title}
                  address={value.address}
                  isReceiver={value.is_receiver}
                />
              ))}
          </Box>
          {showForm && (
            <Box>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FormControl marginTop="12px">
                  <FormLabel
                    color="var(--Text-Primary-default, #000)"
                    fontSize="14px"
                    fontStyle="normal"
                    lineHeight="22px"
                    fontWeight={400}
                  >
                    Wallet name
                  </FormLabel>
                  <Input
                    id="title"
                    {...register('title', {
                      required: 'Wallet name is required',
                    })}
                    placeholder="Add a name"
                    backgroundColor={'#FFF'}
                    color="#909090"
                    border="1px solid  rgba(0, 0, 0, 0.15) "
                    borderRadius="8px"
                  />
                </FormControl>

                <FormControl marginTop="12px">
                  <FormLabel
                    color="var(--Text-Primary-default, #000)"
                    fontSize="14px"
                    fontStyle="normal"
                    lineHeight="22px"
                    fontWeight={400}
                  >
                    Address
                  </FormLabel>
                  <Input
                    id="address"
                    {...register('address', {
                      required: 'Wallet address is required',
                    })}
                    placeholder="Add wallet address"
                    backgroundColor={'#FFF'}
                    color="#909090"
                    border="1px solid  rgba(0, 0, 0, 0.15) "
                    borderRadius="8px"
                  />
                </FormControl>

                <FormControl marginTop="12px">
                  <FormLabel
                    color="var(--Text-Primary-default, #000)"
                    fontSize="14px"
                    fontStyle="normal"
                    lineHeight="22px"
                    fontWeight={400}
                  >
                    Select type
                  </FormLabel>
                  <Controller
                    name="is_receiver"
                    control={control}
                    defaultValue="1"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction="row">
                          <Radio value="1">
                            <Text
                              color=" rgba(0, 0, 0, 0.88)"
                              fontSize="14px"
                              fontStyle=" normal"
                              fontWeight=" 400"
                              lineHeight=" 22px"
                            >
                              Sender (default)
                            </Text>
                          </Radio>
                          <Radio value="2">
                            <Text
                              color=" rgba(0, 0, 0, 0.88)"
                              fontSize="14px"
                              fontStyle=" normal"
                              fontWeight=" 400"
                              lineHeight=" 22px"
                            >
                              Receiver
                            </Text>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </FormControl>

                <Box>
                  <Button
                    background={'#4096FF'}
                    color={'white'}
                    _hover={{
                      bg: '#494949',
                      color: 'white',
                    }}
                    _active={{
                      bg: '#4096FF',
                      color: 'white',
                    }}
                    textTransform="capitalize"
                    fontSize={['14px']}
                    width="108px"
                    fontWeight={500}
                    fontStyle="normal"
                    transition="0.2s all"
                    mt="24px"
                    h="32px"
                    type="submit"
                  >
                    Add Wallet
                  </Button>
                </Box>
                <Box marginTop="12px" marginBottom="24px">
                  <Divider />
                </Box>
              </form>
            </Box>
          )}

          <>
            <Button
              _hover={{
                bg: '#494949',
                color: 'white',
              }}
              _active={{
                bg: '#4096FF',
                color: 'white',
              }}
              color=" var(--Text-Primary-description, #909090)"
              fontSize="14px"
              fontStyle="normal"
              fontWeight=" 400"
              lineHeight="22px"
              leftIcon={<AddIcon />}
              borderRadius=" 6px"
              height="32px"
              border="1px dashed rgba(0, 0, 0, 0.15)"
              background=" var(--Button-colorBgContainer, #FFF)"
              onClick={() => setShowForm(!showForm)}
            >
              Add another wallet
            </Button>
            <Box display="flex" flexDirection="column" gap="10px">
              <Button
                background={'#4096FF'}
                color={'white'}
                _hover={{
                  bg: '#494949',
                  color: 'white',
                }}
                _active={{
                  bg: '#4096FF',
                  color: 'white',
                }}
                textTransform="capitalize"
                fontSize={['14px']}
                fontWeight={500}
                fontStyle="normal"
                width="inherit"
                transition="0.2s all"
                mt="24px"
                w="100%"
                h="46px"
                onClick={() => setStepNumber(6)}
              >
                Save & Continue
              </Button>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
}
export default InOutFlow;
