// 1. Import the utilities
import { extendTheme } from '@chakra-ui/react';

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  sm: '395px',
  md: '700px',
  lg: '1100px',
  xl: '1600px',
  '2xl': '2000px',
};

// const userData = useLoginStore((state: any) => state.userData);
// console.log('userData', userData?.accessToken !== '');
// const isLoggedIn = Object.keys(userData).length > 0 ? true : false;
// console.log('isLoggedIn', isLoggedIn);

// const data: any = localStorage.getItem('coveyInsight');
// const dataObject = JSON.parse(data);

const CustomTheme = extendTheme({
  breakpoints,
  fonts: {
    heading: `'VisbyCF', 'Poppins', sans-serif `,
    body: `'VisbyCF', 'Poppins', sans-serif`,
  },
  styles: {
    global: () => ({
      body: {
        bg: '#353535 !important',
        color: 'white',
      },
    }),
  },
  components: {
    Select: {
      defaultProps: {
        focusBorderColor: '#D3BB8A',
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'none',
      },
    },
    Progress: {
      defaultProps: {
        colorScheme: 'gray',
      },
    },
  },
});

export { CustomTheme };
