import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, useToast } from '@chakra-ui/react';
import configureAxios from '../../../AxiosClient';
import axios from '../../../api/axios';

interface PressureInfoProps {
  initialBuyingPressure: number;
  initialSellingPressure: number;
}

export const PressureInfo: React.FC<PressureInfoProps> = ({ initialBuyingPressure, initialSellingPressure }) => {
  const [activeTab, setActiveTab] = useState<string>('24h');
  const [buyingPressure, setBuyingPressure] = useState<number>(initialBuyingPressure);
  const [sellingPressure, setSellingPressure] = useState<number>(initialSellingPressure);
  const axiosInstance = configureAxios();
  const toast = useToast();

  const tabOptions = ['24h', '7d', '30d'];

  const fetchPressureData = async (period: string) => {
    try {
      const [buyingResponse, sellingResponse] = await Promise.all([
        axios.get(`/api/token/buying-pressure/?period=${period}`),
        axios.get(`/api/token/selling-pressure/?period=${period}`),
      ]);

      if (buyingResponse.status === 200) {
        setBuyingPressure(Number(buyingResponse.data.currentValue) || 0);
      }

      if (sellingResponse.status === 200) {
        setSellingPressure(Number(sellingResponse.data.currentValue) || 0);
      }
    } catch (err) {
      console.error(err);
      // toast({
      //   title: 'There was an error fetching pressure data',
      //   status: 'error',
      //   isClosable: true,
      // });
    }
  };

  useEffect(() => {
    fetchPressureData(activeTab);
  }, [activeTab]);

  const totalPressure = buyingPressure + sellingPressure;
  const buyingPercentage = (buyingPressure / totalPressure) * 100;
  const pressureEmoji = buyingPressure > sellingPressure ? '🚀' : '💀';

  return (
    <Box bg="white" borderRadius="lg" p={4} boxShadow="sm">
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize="xl">{pressureEmoji}</Text>
        <Flex>
          {tabOptions.map((tab) => (
            <Button
              key={tab}
              size="xs"
              variant="ghost"
              onClick={() => setActiveTab(tab)}
              color={activeTab === tab ? '#4096FF' : '#909090'}
              fontWeight="medium"
              mr={tab !== '30d' ? 2 : 0}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            >
              {tab}
            </Button>
          ))}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Text fontSize="sm" fontWeight="600" color="#000">
          Buying
        </Text>
        <Text fontSize="sm" fontWeight="600" color="#000">
          Selling
        </Text>
      </Flex>
      <Box position="relative" height="8px" mb={2}>
        <Box position="absolute" left="0" top="0" height="100%" width="100%" bg="#FF4D4F" borderRadius="full" />
        <Box
          position="absolute"
          left="0"
          top="0"
          height="100%"
          width={`${buyingPercentage}%`}
          bg="#24D67A"
          borderRadius="full"
        />
        <Box
          position="absolute"
          left={`${buyingPercentage}%`}
          top="0"
          height="100%"
          width="2px"
          bg="white"
          transform="translateX(-50%)"
        />
      </Box>
      <Flex justifyContent="space-between">
        <Text fontSize="sm" fontWeight="400" color="#000">
          ${buyingPressure.toFixed(2)}k
        </Text>
        <Text fontSize="sm" fontWeight="400" color="#000">
          ${sellingPressure.toFixed(2)}k
        </Text>
      </Flex>
    </Box>
  );
};
