import React, { useState, useEffect } from 'react';
import { Box, Text, Button, VStack, Flex } from '@chakra-ui/react';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from '../../../../api/axios';

interface Investor {
  wallet_address: string;
  name: string;
}

const InvestorsPreviewForm: React.FC = () => {
  const [investors, setInvestors] = useState<Investor[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const fetchInvestors = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/user/address-book/?page=${page}&page_size=${pageSize}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      setInvestors(response.data.results);
      setTotalItems(response.data.count);
    } catch (error) {
      console.error('Error fetching investors:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestors(1, 5);
  }, []);

  const columns: ColumnsType<Investor> = [
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'wallet_address',
      key: 'wallet_address',
      width: '60%',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchInvestors(pagination.current || 1, pagination.pageSize || 5);
  };

  const handleGoToDashboard = () => {
    window.location.href = '/';
  };

  return (
    <VStack spacing={6} align="stretch" maxWidth="952px" margin="auto" p={4}>
      <Text fontSize="24px" fontWeight="bold" textAlign="center" color="#272727">
        Is this file correct?
      </Text>
      <Flex
        direction="column"
        borderWidth="1px"
        borderColor="rgba(0, 0, 0, 0.06)"
        borderRadius="20px"
        bg="white"
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)"
        width="952px"
        height="370px"
      >
        <Text fontSize="14px" fontWeight="400" p={6} color="#272727">
          File Preview
        </Text>
        <Box flex={1} overflowY="auto" marginLeft="5px">
          <Table
            columns={columns}
            dataSource={investors}
            rowKey={(record) => record.wallet_address}
            pagination={{
              total: totalItems,
              pageSize: 5,
              showSizeChanger: false,
              showQuickJumper: false,
              showTotal: (total) => `Total ${total} items`,
              size: 'small',
              position: ['bottomCenter'],
            }}
            onChange={handleTableChange}
            loading={loading}
            size="small"
            style={{ width: '100%' }}
          />
        </Box>
      </Flex>
      <Text textAlign="center" color="gray.500">
        If it looks good, proceed to dashboard.
      </Text>
      <Flex justifyContent="center">
        <Button
          bg="#2d8dfb"
          color="white"
          _hover={{ bg: '#2a7fe6' }}
          width={{ base: 'full', sm: 'auto' }}
          minWidth="200px"
          height="40px"
          borderRadius="6px"
          onClick={handleGoToDashboard}
        >
          Go to dashboard
        </Button>
      </Flex>
    </VStack>
  );
};

export default InvestorsPreviewForm;
