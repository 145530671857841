import { Box, Image, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { InfoCircle } from '../../Icons/InfoCircle';
import arrowOutward from '../../assets/images/svgs/arrowOutward.svg';
import { useLoginStore } from '../../store';
import { getExplorerUrl, openInNewTab } from '../../utils/walletUtils/walletutils';
import DaysFilter from './DaysFilter';

interface BiggestBSProps {
  WalletAddress: string;
  WalletImage: string;
  Date: string;
  TotalAmount: string;
  title: string;
  color: string;
  TxHash: string;
  onTimeRangeChange: (range: string) => void;
  isLoading?: boolean;
  type: 'receive' | 'send';
  defaultTimeRange?: string;
}

const BiggestBS: React.FC<BiggestBSProps> = ({
  WalletAddress,
  TxHash,
  color,
  title,
  WalletImage,
  Date,
  TotalAmount,
  onTimeRangeChange,
  isLoading = false,
  type,
  defaultTimeRange = 'all',
}) => {
  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);
  const [localTimeRange, setLocalTimeRange] = useState(defaultTimeRange);

  const displayType = type === 'receive' ? 'Receive' : 'Send';

  const handleTimeRangeChange = (range: string) => {
    setLocalTimeRange(range);
    onTimeRangeChange(range);
  };

  return (
    <Box
      width={'534px'}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="10px"
      flex="1 0 0"
      alignSelf="stretch"
      position="relative"
    >
      <Box
        width={'534px'}
        display="flex"
        padding="24px"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        flex="1 0 0"
        gap={'10px'}
        alignSelf="stretch"
        borderRadius="20px"
        background="var(--Cascader-colorWhite, #FFF)"
      >
        <Box display={'flex'} justifyContent="space-between" width="100%" flexDirection={'row'} alignItems="center">
          <Box display="flex" alignItems="center" gap="6px" color="var(--Text-Primary-default, #000)">
            <Text fontFamily="VisbyCF" fontSize="20px" fontWeight="700" lineHeight="28px">
              {title}
            </Text>
            <InfoCircle boxSize="24px" />
          </Box>
          <DaysFilter onTimeRangeChange={handleTimeRangeChange} activeTimeRange={localTimeRange} />
        </Box>

        <Box display={'flex'} justifyContent="space-between" width="100%" flexDirection={'column'} gap={'12px'}>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Box display="flex" padding="5px 8px" alignItems="center" gap="4px" borderRadius="6px" background={color}>
              <Text
                color="var(--green-1, #EFFBF5)"
                fontFamily="VisbyCF"
                fontSize="12px"
                fontWeight="500"
                lineHeight="24px"
              >
                {displayType}
              </Text>
            </Box>

            <Text
              color="var(--Text-Primary-default, #000)"
              textAlign="right"
              fontFamily="VisbyCF"
              fontSize="14px"
              fontWeight="600"
              lineHeight="22px"
            >
              {Date}
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
            <Text
              color="var(--Text-Primary-default, #000)"
              fontFamily="VisbyCF"
              fontSize="56px"
              fontWeight="700"
              lineHeight="46px"
            >
              {TotalAmount}
            </Text>
            <Text
              color="var(--Text-Primary-default, #000)"
              fontFamily="VisbyCF"
              fontSize="14px"
              fontWeight="500"
              lineHeight="22px"
            >
              Amount
            </Text>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch" width="100%">
          <Box
            display="flex"
            padding="6px 12px 6px 8px"
            alignItems="center"
            gap="4px"
            borderRadius="6px"
            background="var(--Neutrals-2, #F6F6F6)"
          >
            <Image src={WalletImage} alt="Wallet Icon" boxSize="24px" />
            <Text
              color="var(--Text-Primary-description, #909090)"
              fontFamily="VisbyCF"
              fontSize="14px"
              fontWeight="500"
              lineHeight="22px"
            >
              {WalletAddress}
            </Text>
          </Box>

          {TxHash && (
            <Box
              onClick={() => openInNewTab(getExplorerUrl(Number(chainId), TxHash))}
              display="flex"
              cursor="pointer"
              padding="4px 0px"
              alignItems="center"
              gap="4px"
              borderRadius="6px"
              _hover={{ opacity: 0.8 }}
              transition="opacity 0.2s"
            >
              <Text
                color="var(--Text-action, #4096FF)"
                fontFamily="VisbyCF"
                fontSize="14px"
                fontWeight="600"
                lineHeight="22px"
              >
                View transaction details
              </Text>
              <Image src={arrowOutward} alt="Details Icon" boxSize="20px" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BiggestBS;
