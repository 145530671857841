import { Box, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import 'chartjs-adapter-moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import configureAxios from '../../../AxiosClient';
import { loadingStore } from '../../../store';
import { SupplyData, TextualComponentProps, TokenInfoProps, TokenInformation } from '../../../interfaces';
import axios from '../../../api/axios';

ChartJS.register(ArcElement, Tooltip, Legend);

export const TextualComponent = (props: TextualComponentProps) => {
  const { circleColor, text, percentage, value } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop="20px">
      <Box display="flex" alignItems="center" gap={'8px'}>
        <Box w="18px" h="18px" bg={circleColor} borderRadius="100%"></Box>
        <Text color={'#909090'} fontSize="14px" fontWeight={500}>
          {text}
        </Text>
      </Box>
      <Box display="flex" alignItems="center" gap={'10px'}>
        <Text color={'#909090'} fontSize="14px" fontWeight={500}>
          {value.toLocaleString()}
        </Text>
        <Text color={'#4096FF'} fontSize="14px" fontWeight={500}>
          {`${percentage.toFixed(2)}%`}
        </Text>
      </Box>
    </Box>
  );
};

export const TokenInfo = (props: TokenInfoProps) => {
  const { marginTop, text1, text2, textColor } = props;
  return (
    <Box display={'flex'} justifyContent="space-between" mt={marginTop}>
      <Text color={textColor} fontSize="14px" fontWeight={500}>
        {text1}
      </Text>
      <Text color={textColor} fontSize="14px" fontWeight={500}>
        {text2}
      </Text>
    </Box>
  );
};

function CirculatingSupply() {
  const axiosInstance = configureAxios();
  const toast = useToast();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const [tokenInfo, setTokenInfo] = useState<TokenInformation>();
  const [supplyData, setSupplyData] = useState<SupplyData[]>([]);
  const Colors = [
    '#1677FF',
    '#353535',
    '#69B1FF',
    '#7330e6',
    '#140929',
    '#694d9e',
    '#d9184e',
    '#590c21',
    '#c9553a',
    '#d96f23',
    '#d4d0c7',
    '#3ae30b',
    '#84eb67',
    '#204216',
  ];

  const getCirculatingSupply = () => {
    axios
      .get('/api/token/coin-circulating-supply/')
      .then((res) => {
        const isStatusOk = res.status === 200;
        if (isStatusOk) {
          setLoading(false);
          setTokenInfo(res.data.token_info);
          setSupplyData(res.data.supply_data);
        } else {
          setLoading(false);
          // toast({
          //   title: 'There was an error fetching data',
          //   status: 'error',
          //   isClosable: true,
          // });
        }
      })
      .catch((err: unknown) => {
        setLoading(false);
        console.log(err);
      });
  };

  const donutData = (SupplyData: SupplyData[]): number[] => {
    return SupplyData.map((data) => Math.abs(data.value));
  };

  useEffect(() => {
    setLoading(true);
    getCirculatingSupply();
  }, []);

  const data = {
    datasets: [
      {
        data: supplyData ? donutData(supplyData) : [],
        backgroundColor: Colors,
        borderWidth: 8,
        borderRadius: 10,
        cutout: 70,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box
      mt="16px"
      borderRadius={'20px'}
      bg="#FFF"
      p="24px"
      minWidth={'100%'}
      padding="24px"
      display={['block', 'block', 'flex', 'block', 'block']}
      className="gsap-item"
    >
      <Box
        w="217px"
        margin={['0 auto', '0 auto', 'unset', '0 auto', '0 auto']}
        position="relative"
        display={['block', 'block', 'flex', 'block', 'block']}
        alignItems="center"
      >
        <Doughnut data={data} options={options} />
        <Box className="donut-inner" display={'flex'} flexDirection="column">
          <Text color={'black'} fontSize="18px" fontWeight={600}>
            Circulating
          </Text>
          <Text color={'#909090'} fontSize="14px" fontWeight={500}>
            {tokenInfo?.circulating_supply.toLocaleString()}
          </Text>
        </Box>
      </Box>
      <Box marginTop={['30px', '30px', 'unset', '15px', '35px']} flex={1}>
        {supplyData.length > 0 &&
          supplyData.map((data, index) => (
            <TextualComponent
              key={data.value}
              number={data.id}
              circleColor={Colors[index]}
              percentage={data.percentage}
              value={data.value}
              text={data.title}
            />
          ))}
        {/* <Box bg="#F7F7F7" marginTop={['30px', '30px', '30px', '15px', '35px']} padding="30px 30px" borderRadius="20px">
          <TokenInfo marginTop="0px" text1="Next unlock in" text2="Tokens unlocking" textColor="#909090" />
          <TokenInfo marginTop="9px" text1="- days" text2="-" textColor="#000000" />
          <TokenInfo marginTop="9px" text1="- days" text2="-" textColor="#000000" />
          <TokenInfo marginTop="9px" text1="- days" text2="-" textColor="#000000" />
        </Box> */}
      </Box>
    </Box>
  );
}

export default CirculatingSupply;
