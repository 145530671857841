// src/Icons/BalanceFilter.tsx
import { Icon } from '@chakra-ui/react';
import React from 'react';

interface BalanceFilterProps {
  sort: 'none' | 'asc' | 'desc';
  onSort: () => void;
}

export const BalanceFilter = ({ sort, onSort }: BalanceFilterProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    cursor="pointer"
    onClick={onSort}
  >
    <mask id="mask0_3128_1033" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="18">
      <rect x="0.666718" y="18" width="18" height="18" transform="rotate(-90 0.666718 18)" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3128_1033)">
      <path
        d="M13.8667 11.25C13.8667 11.35 13.848 11.4438 13.8105 11.5313C13.773 11.6188 13.7167 11.7 13.6417 11.775L10.1917 15.225C10.1167 15.3 10.0355 15.3531 9.94797 15.3844C9.86047 15.4156 9.76672 15.4313 9.66672 15.4313C9.56672 15.4313 9.47297 15.4156 9.38547 15.3844C9.29797 15.3531 9.21672 15.3 9.14172 15.225L5.69172 11.775C5.61672 11.7 5.56047 11.6188 5.52297 11.5313C5.48547 11.4438 5.46672 11.35 5.46672 11.25C5.46672 11.05 5.5386 10.875 5.68235 10.725C5.8261 10.575 6.01047 10.5 6.23547 10.5H13.098C13.323 10.5 13.5073 10.575 13.6511 10.725C13.7948 10.875 13.8667 11.05 13.8667 11.25Z"
        fill={sort === 'asc' ? 'black' : 'none'}
        stroke="#1C1B1F"
      />
      <path
        d="M13.8667 6.75C13.8667 6.95 13.7948 7.125 13.6511 7.275C13.5073 7.425 13.323 7.5 13.098 7.5L6.23547 7.5C6.01047 7.5 5.8261 7.425 5.68235 7.275C5.5386 7.125 5.46672 6.95 5.46672 6.75C5.46672 6.65 5.48547 6.55625 5.52297 6.46875C5.56047 6.38125 5.61672 6.3 5.69172 6.225L9.14172 2.775C9.21672 2.7 9.29797 2.64688 9.38547 2.61563C9.47297 2.58438 9.56672 2.56875 9.66672 2.56875C9.76672 2.56875 9.86047 2.58438 9.94797 2.61563C10.0355 2.64688 10.1167 2.7 10.1917 2.775L13.6417 6.225C13.7167 6.3 13.773 6.38125 13.8105 6.46875C13.848 6.55625 13.8667 6.65 13.8667 6.75Z"
        fill={sort === 'desc' ? 'black' : 'none'}
        stroke="#1C1B1F"
      />
    </g>
  </Icon>
);
