import React, { useState, useEffect } from 'react';
import { DatePicker, Space, Button, Select, Table, message } from 'antd';
import axios from '../api/axios';
import moment from 'moment';
import api, { TransactionParams } from '../api/ApiCalls/user';
import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLoginStore } from '../store';
const { RangePicker } = DatePicker;
const { Option } = Select;

function AllTransactions() {
  const [dateRange, setDateRange] = useState<[moment.Moment | null, moment.Moment | null]>([null, null]);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [inflowOutflowTitle, setInflowOutflowTitle] = useState<string | undefined>(undefined);
  const [inflowOutflowTitles, setInflowOutflowTitles] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>('block_time');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const navigate = useNavigate();
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

  const handleDateChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleTitleChange = (value: string) => {
    setInflowOutflowTitle(value === 'All' ? undefined : value);
  };

  const fetchData = async (page = 1, size = 10) => {
    setLoading(true);
    const params: any = {
      page,
      page_size: size,
      sort_by: sortBy,
      sort_direction: sortDirection,
    };

    if (dateRange[0] && dateRange[1]) {
      params.start_date = dateRange[0].format('YYYY-MM-DD');
      params.end_date = dateRange[1].format('YYYY-MM-DD');
    }

    if (inflowOutflowTitle) {
      params.inflow_outflow_title = inflowOutflowTitle;
    }

    try {
      const response = await axios.get('/api/user/user-transactions/', {
        params,
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      setResponseData(response.data.results);
      setTotalItems(response.data.count);
      setInflowOutflowTitles(['All', ...response.data.inflow_outflow_titles]);
      setError(null);
    } catch (err: any) {
      setError(err.message);
      setResponseData([]);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [currentPage, pageSize, sortBy, sortDirection]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field) {
      setSortBy(sorter.field);
      setSortDirection(sorter.order === 'ascend' ? 'asc' : 'desc');
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  };

  const navigateToSinglePage = (address: string) => {
    setSelectedWalletAddress(address);
    navigate(`/transactions/${address}`, {
      state: {
        address: address,
        labels: [],
      },
    });
  };

  const columns = [
    {
      title: 'Transaction Hash',
      dataIndex: 'transaction_hash',
      key: 'transaction_hash',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: any) => {
        const numValue = parseFloat(value);
        if (isNaN(numValue)) return value;
        return numValue.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      sorter: true,
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      key: 'receiver',
      render: (address: string) => (
        <a onClick={() => navigateToSinglePage(address)} style={{ color: '#4096ff', cursor: 'pointer' }}>
          {address}
        </a>
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      render: (address: string) => (
        <a onClick={() => navigateToSinglePage(address)} style={{ color: '#4096ff', cursor: 'pointer' }}>
          {address}
        </a>
      ),
    },
    {
      title: 'Transfer Type',
      dataIndex: 'transfer_type',
      key: 'transfer_type',
    },
    {
      title: 'Block Time',
      dataIndex: 'block_time',
      key: 'block_time',
      render: (value: string) => (moment(value).isValid() ? moment(value).format('YYYY-MM-DD HH:mm:ss') : value),
      sorter: true,
    },
  ];

  return (
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
      <Space>
        <RangePicker onChange={handleDateChange} />
        <Select
          style={{ width: 200 }}
          placeholder="Select Inflow/Outflow Title"
          onChange={handleTitleChange}
          allowClear
          defaultValue="All"
        >
          {inflowOutflowTitles.map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Button onClick={() => fetchData(1, pageSize)}>Fetch Data</Button>
      </Space>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <Table
        columns={columns}
        dataSource={responseData}
        rowKey="transaction_hash"
        loading={loading}
        onChange={handleTableChange}
        pagination={false}
      />
      <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `Total ${total} items`}
        pageSizeOptions={['10', '50', '100']}
        style={{ marginTop: '16px', textAlign: 'right' }}
      />
    </Space>
  );
}

export default AllTransactions;
