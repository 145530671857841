import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Account = (props: IconProps) => (
  <Icon width="44px" height="44px" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="url(#pattern0_3054_30037)" />
    <defs>
      <pattern id="pattern0_3054_30037" patternContentUnits="objectBoundingBox" width="1" height="1"></pattern>
      <image id="image0_3054_30037" width="500" height="500" />
    </defs>
  </Icon>
);
