import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const ArrowIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.584 4.767C18.2868 4.767 18.9609 5.0462 19.4578 5.54317C19.9548 6.04014 20.234 6.71418 20.234 7.417V16.249C20.234 16.9518 19.9548 17.6259 19.4578 18.1228C18.9609 18.6198 18.2868 18.899 17.584 18.899H16.123V4.767H17.584ZM17.584 3C18.7552 3 19.8784 3.46526 20.7066 4.29342C21.5347 5.12158 22 6.2448 22 7.416V16.249C22.0001 16.829 21.886 17.4033 21.6641 17.9392C21.4423 18.4751 21.117 18.9621 20.7069 19.3722C20.2969 19.7824 19.81 20.1078 19.2742 20.3298C18.7383 20.5517 18.164 20.666 17.584 20.666H6.416C5.83591 20.666 5.26151 20.5517 4.72561 20.3297C4.1897 20.1076 3.7028 19.7822 3.29271 19.3719C2.88262 18.9616 2.55738 18.4745 2.33557 17.9385C2.11377 17.4025 1.99974 16.8281 2 16.248V7.416C2 6.2448 2.46526 5.12158 3.29342 4.29342C4.12158 3.46526 5.2448 3 6.416 3H17.584ZM14.356 4.767V18.899H6.416C5.71318 18.899 5.03914 18.6198 4.54217 18.1228C4.0452 17.6259 3.766 16.9518 3.766 16.249V7.416C3.766 6.71318 4.0452 6.03914 4.54217 5.54217C5.03914 5.0452 5.71318 4.766 6.416 4.766H14.356V4.767Z"
      fill="#909090"
    />
  </Icon>
);
