import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WalletInfo } from '../components/tokenHolder/walletInfo/WalletInfo';

export const SingleTokenHolderDemo = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        height="44px"
        as={'button'}
        borderRadius="24px"
        backgroundColor={'#FFF'}
        padding={'10px 15px 10px 10px'}
        display={'flex'}
        gap="10px"
        alignItems="center"
        margin={['40px unset unset unset', '40px unset unset unset', '40px 10px 10px 10px', '40px unset unset unset']}
      >
        <ChevronLeftIcon />
        <Text fontSize="16px" fontWeight="500" onClick={() => navigate('/SankeyChart')}>
          Back to list
        </Text>
      </Box>
      <WalletInfo />
    </Box>
  );
};
