import axiosClient, { AxiosInstance } from 'axios';

const TIMEOUT = 35000; // 35sec
const SERVER_URL = process.env.REACT_APP_API_HOST || 'https://dev.api.covey.academy';
//const SERVER_URL = process.env.REACT_APP_API_HOST || 'http://localhost:8000';

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: TIMEOUT,
});

// Helper function to handle logout
const handleLogout = () => {
  console.log('handleLogout');
  console.log('Logging out and redirecting to login page');
  localStorage.clear(); // Clear all localStorage
  delete axios.defaults.headers.common['Authorization'];
  window.location.href = '/'; // Changed to root path
};

const refreshToken = async () => {
  try {
    const refreshTokenString = localStorage.getItem('refresh_token');

    if (!refreshTokenString) {
      console.log('No refresh token found, redirecting to login');
      handleLogout();
      return null;
    }

    console.log('refresh token string calling endpoint', refreshTokenString);
    const res = await axios.post(
      '/api/user/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshTokenString}`,
        },
      },
    );

    if (res.data.access_token) {
      localStorage.setItem('access_token', res.data.access_token);
      localStorage.setItem('refresh_token', res.data.refresh_token);
      return res.data;
    } else {
      console.log('Invalid refresh token response, redirecting to login');
      handleLogout();
      return null;
    }
  } catch (e) {
    console.log('Error refreshing token:', e);
    handleLogout();
    return null;
  }
};

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('access_token');

    if (!token) {
      console.log('No access token found, redirecting to login');
      // handleLogout();
      //return Promise.reject('No access token');
    }

    if (config?.headers && token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['x-api-key'] = process.env.REACT_APP_TITLE_X_KEY || '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc';
    return config;
  },
  (error) => {
    console.log('Request interceptor error:', error);
    handleLogout();
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log('Error response:', error.response?.data);

    // Check for specific error codes that require logout
    if (error.response?.data?.code === '1001') {
      // If refresh token endpoint fails, logout
      if (originalRequest.url.includes('/api/user/refresh')) {
        console.log('Refresh token expired, redirecting to login');
        handleLogout();
        return Promise.reject(error);
      }

      // Try to refresh token once
      if (!originalRequest._retry) {
        console.log('Token expired, attempting refresh');
        originalRequest._retry = true;

        try {
          const res = await refreshToken();
          if (!res) {
            console.log('Token refresh failed, redirecting to login');
            handleLogout();
            return Promise.reject(error);
          }

          axios.defaults.headers.common['Authorization'] = `Bearer ${res.access_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${res.access_token}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.log('Error during token refresh, redirecting to login');
          handleLogout();
          return Promise.reject(refreshError);
        }
      } else {
        console.log('Multiple refresh attempts detected, redirecting to login');
        handleLogout();
        return Promise.reject(error);
      }
    }

    // For all other errors that should trigger a logout
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log('Authentication error, redirecting to login');
      handleLogout();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export default axios;
