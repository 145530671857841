export function shortenWalletAddress(str: string) {
  if (str.length <= 10) {
    return str;
  }

  const firstPart = str.slice(0, 5);
  const lastPart = str.slice(-5);

  return `${firstPart}...${lastPart}`;
}

export function openInNewTab(url: string) {
  window.open(url, '_blank');
}

export const getExplorerUrl = (chainId: number, hash: string): string => {
  switch (chainId) {
    case 11155111:
      return `https://sepolia.etherscan.io/tx/${hash}`;
    case 1:
      return `https://etherscan.io/tx/${hash}`;
    case 97:
      return `https://testnet.bscscan.com/tx/${hash}`;
    case 56:
      return `https://bscscan.com/tx/${hash}`;
    case 8453:
      return `https://basescan.org/tx/${hash}`;
    case 137:
      return `https://polygonscan.com/tx/${hash}`;
    case 42161:
      return `https://arbiscan.io/tx/${hash}`;
    case 43114:
      return `https://snowtrace.io/tx/${hash}`;
    case 10:
      return `https://optimistic.etherscan.io/tx/${hash}`;
    case 42220:
      return `https://ftmscan.com/tx/${hash}`;
    case 1284:
      return `https://moonbeam.moonscan.io/tx/${hash}`;
    case 128:
      return `https://moonriver.moonscan.io/tx/${hash}`;
    case 196:
      return `https://hecoinfo.com/tx/${hash}`;
    case 324:
      return `https://explorer.zksync.io/tx/${hash}`;

    default:
      return '';
  }
};
